import PropTypes from 'prop-types';
import VitalPair from './vitalPair';
import vitalPairBloodPressure from './vitalPairBloodPressure';

const vitalChartShape = PropTypes.shape({
  chart: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([VitalPair, vitalPairBloodPressure])).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  unit: PropTypes.array,
  name: PropTypes.string.isRequired,
  xAxis: PropTypes.string.isRequired,
  yAxis: PropTypes.string.isRequired,
});

export default vitalChartShape;
