import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import '../PatientCardHeader.scss';
import ValueToolTip from '../../../../../../common/value-tool-tip/ValueToolTip';
import e2eTestIds from '../../../../../../e2eTestIds';

const BloodPressure = (props) => {

  const { valueArr, unit, t } = props;

  const tooltipId = uuidv4();
  const diastolic = valueArr && valueArr[0] ? valueArr[0].diastolic : undefined;
  const systolic = valueArr && valueArr[0] ? valueArr[0].systolic : undefined;
  const timestamp = valueArr && valueArr[0] ? valueArr[0].timestamp : undefined;
  const value = `${systolic || '--'}/${diastolic || '--'}`;
  return (
    <span className="on-hover-blue" data-for={tooltipId} data-tip>
      <span className="value-style" data-testid={e2eTestIds.dashboard.patientCard.bloodPressure}>
        { value }&nbsp;
      </span>
      <span className="unit-style">
        { unit }
      </span>
      <ValueToolTip tooltipId={tooltipId} name={t('title.blood_pressure')} timestamp={timestamp || '--'} formatDate={!!timestamp}/>
    </span>
  );
};

export default withTranslation('common')(BloodPressure);

BloodPressure.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  valueArr: PropTypes.array.isRequired,
  unit: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
