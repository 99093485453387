import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPrint, faWarning } from '@fortawesome/free-solid-svg-icons';
import './RegistrationSuccess.scoped.scss';
import ReactRouterPrompt from 'react-router-prompt';
import { Button, Modal } from 'react-bootstrap';
import e2eTestIds from '../e2eTestIds';

function RegistrationSuccess(props) {
  const { t } = props;

  const promptContent = ({ isActive, onConfirm, onCancel }) => (
    <Modal show={isActive} onHide={onCancel} centered>
      <Modal.Header>
        <Modal.Title>{t('create.leaveModalHeader')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{t('create.leaveModalContent')}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          {t('create.leaveModalCancel')}
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          {t('create.leaveModalConfirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      <ReactRouterPrompt when>{ promptContent }</ReactRouterPrompt>
      <div
        style={{
          maxWidth: '50%',
          marginTop: 30,
          marginBottom: 30,
          display: 'flex',
          flexDirection: 'column',
          gap: 20,
        }}
        className="overlay"
      >
        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
          <FontAwesomeIcon icon={faCheck} size="4x" color="green" />
          <h3 style={{ margin: 0 }}>{t('create.success')}</h3>
        </div>
        <div>
          <p style={{ margin: 0, fontWeight: 'bold' }}>
            {t('create.studyNumber', { studyNumber: props.studyNumber })}
          </p>
          <p style={{ margin: 0, fontWeight: 'bold' }} data-testid={e2eTestIds.registration.registrationKey}>
            {t('create.registrationKey', {
              registrationKey: props.registrationKey,
            })}
          </p>
        </div>
        <p style={{ margin: 0 }}>{t('activateInstructions')}</p>
        <div
          style={{
            display: 'flex',
            gap: 20,
            borderRadius: '10px',
            backgroundColor: 'white',
            padding: 15,
            alignItems: 'center',
            maxWidth: 800,
            border: '1px solid black',
          }}
          className="no-print"
        >
          <FontAwesomeIcon icon={faWarning} size="2x" color="orange" />
          <p style={{ margin: 0, textAlign: 'justify' }}>
            {t('create.saveWarning')}
          </p>
        </div>
        <button
          style={{
            display: 'flex',
            gap: 20,
            alignItems: 'center',
            width: 'max-content',
            padding: '10px 40px',
            cursor: 'pointer',
            backgroundColor: '#41619c',
            border: 'none',
          }}
          className="buttonSave no-print"
          onClick={() => window.print()}
        >
          <p style={{ margin: 0 }}>{t('print.buttonLabel')}</p>
          <FontAwesomeIcon icon={faPrint} size="2x" />
        </button>
      </div>
    </>
  );
}

RegistrationSuccess.propTypes = {
  t: PropTypes.func.isRequired,
  registrationKey: PropTypes.string.isRequired,
  studyNumber: PropTypes.string.isRequired,
};

export default withTranslation('common')(RegistrationSuccess);
