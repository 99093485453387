import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import './Login.scoped.scss';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import e2eTestIds from '../e2eTestIds';
import { smileVersion } from '../../helpers/version';
import useAuth from '../../hooks/useAuth';

function Login(props) {
  const { t } = props;
  const { login } = useAuth();
  const imgUrl = process.env.PUBLIC_URL.concat('/img/bglogin.jpeg');

  return (
    <div
      className="background"
      style={{ backgroundImage: `url(${imgUrl})` }}
    >
      <h1><FontAwesomeIcon size="sm" icon={faUser}/>{t('login.header')}</h1>
      <br/>
      <div className="text-center login-button-container">
        <button
          onClick={login}
          className="btn btn-block btn-primary"
          data-testid={e2eTestIds.login.submit}
        >
          {t('login.loginButton')}
        </button>
      </div>
      <a href="/licenses" className="license-button">{t('login.licenseButton')}</a>
      <p className="version-info">{smileVersion}</p>
    </div>
  );
}

Login.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(Login);
