import axios from 'axios';
import { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import useAuth from '../../hooks/useAuth';

function AuthHandler() {
  const { logout, refreshToken } = useAuth();

  // Axios interceptor
  useEffect(() => {
    axios.interceptors.response.use(async (response) => {
      if (response.status === 401) {
        await logout();
        return null;
      }

      return response;
    });
  }, []);

  // Token refresh handler
  useEffect(() => {
    // Refresh token if it's expiring in the next 2 minutes
    const refreshTimeSeconds = 2 * 60;
    const tokenRefresher = setInterval(() => {
      refreshToken(refreshTimeSeconds);
    }, (refreshTimeSeconds / 2) * 1000);

    return () => clearInterval(tokenRefresher);
  }, []);
}

export default AuthHandler;
