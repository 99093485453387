import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';
import EvaluationCheckboxes from '../evaluation-checkboxes/EvaluationCheckboxes';
import './NameImagesEvaluation.scoped.scss';

const NameImagesEvaluation = (props) => {
  const { t } = props;

  return (
    <div className="card col-12 corner-radius">
      <div className="card-body">
        <p>{t('questionnaire.nameImages.title')}</p>
        <Table striped hover>
          <thead>
            <tr>
              <th>{t('questionnaire.nameImages.tableHeaders.question')}</th>
              <th>
                {t('questionnaire.nameImages.tableHeaders.answerPatient')}
              </th>
              <th>
                {t('questionnaire.nameImages.tableHeaders.answerExpected')}
              </th>
              <th>{t('questionnaire.nameImages.tableHeaders.evaluation')}</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((entry, index) => (
              <tr key={entry.imgId}>
                <td>
                  {t('questionnaire.nameImages.questions.nameImage')}
                  <br />
                  <img
                    src={`/objectNamingImages/${entry.imgId}.svg`}
                    alt={t('questionnaire.nameImages.imgAlt')}
                    className="name-images-img"
                  />
                </td>
                <td>
                  {entry.answerPatient ??
                    t('questionnaire.wasSkippedPlaceholder')}
                </td>
                <td>{entry.answerCorrect.join(', ')}</td>
                <td>
                  <EvaluationCheckboxes
                    groupName={`name-images${index}`}
                    accepted={entry.accepted}
                    setAccepted={(value) => {
                      props.setOverwrite(value, index);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default withTranslation('common')(NameImagesEvaluation);

NameImagesEvaluation.propTypes = {
  t: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  setOverwrite: PropTypes.func.isRequired,
};
