import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { withTranslation } from 'react-i18next';
import DayCalculatorService from '../../services/dayCalculatorService/dayCalculatorService';
import ValueToolTip from './value-tool-tip/ValueToolTip';

/**
 * Component which renders the days since the Transplantation.
 */
const DayCalculator = (props) => {

  const {
    dayOfTransplantation, tooltipFlag, tooltipText,
  } = props;
  const daysSince = DayCalculatorService.calcDaysSinceTransplant(dayOfTransplantation);
  const tooltipId = uuidv4();

  return (
    <span className="days-since" data-tip data-for={tooltipId}> {daysSince}
      {tooltipFlag &&
        <ValueToolTip
          tooltipId={tooltipId}
          name={tooltipText}
          timestamp={dayOfTransplantation}
          showYearInTimestamp
          showTimeInTimestamp={false}
        />}
    </span>
  );
};

export default withTranslation('common')(DayCalculator);

DayCalculator.propTypes = {
  dayOfTransplantation: PropTypes.string.isRequired,
  tooltipFlag: PropTypes.bool.isRequired,
  tooltipText: PropTypes.string.isRequired,
};
