import errors from '../../errors';
import ApiService from '../apiService';

class PatientCommentsCheckedService {

  async setPatientCommentsChecked(id) {
    // throw error if the id is null or undefined
    if (!id) {
      return Promise.reject(new Error(errors.noIdErr));
    }

    const endpoint = process.env.REACT_APP_SINGLE_PATIENT_COMMENT_CHECK_ENDPOINT.replace('%id', id);
    return ApiService.postData(endpoint, {})
      .catch(() => Promise.reject(new Error(`${errors.noPatientDetailsResponseErr} ${id}`)));
  }
}

export default new PatientCommentsCheckedService();
