import { withTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';
import './PatientInformationForm.scss';
import e2eTestIds from '../e2eTestIds';
import TreatmentOptions from '../../prop-types/treatmentOptions';
import useShowLastPatientStudyNumberHint from '../feature-flags/useShowLastPatientStudyNumberHint';

function PatientInformationForm(props) {
  const { t } = props;

  const onInputValueChange = async (event) => {
    event.preventDefault();
    event.persist();
    if (props.editFeedback !== '') {
      props.setEditFeedback('');
    }

    // set data in current patient
    // (is synchronized with the redux store and the backend during submit)
    props.currentPatient[event.target.name] = event.target.value;
    if (
      event.target.name === 'study_number' &&
      props.resetPatientAlreadyExists
    ) {
      props.resetPatientAlreadyExists();
    }
  };
  const lastPatientStudyNumber = useShowLastPatientStudyNumberHint();
  return (
    <form onSubmit={props.onSubmit} className="editForm overlay">
      <div className="fieldsContainer">
        {!props.editMode && (
          <>
            <label className="textHeader">{lastPatientStudyNumber ? t('edit.studyNumberPrevious', { studyNumber: lastPatientStudyNumber }) : t('edit.studyNumber')}</label>
            <input
              className="editFormInput"
              id="study_number"
              required
              title={t('edit.studyNumberRequired')}
              type="text"
              placeholder={props.currentPatient.study_number}
              name="study_number"
              onChange={onInputValueChange}
              data-testid={e2eTestIds.patientForm.patientId}
            />
            {props.patientAlreadyExists && (
              <p style={{ color: 'red' }}>{t('edit.patient_already_exists')}</p>
            )}
          </>
        )}
        <label className="textHeader">{t('edit.treatment')}</label>
        <select
          className="editDropdown"
          id="treatment"
          required
          name="treatment"
          onChange={onInputValueChange}
          defaultValue={props.currentPatient.treatment ?? ''}
          data-testid={e2eTestIds.patientForm.treatment}
        >
          {props.availableTreatments.length > 1 && <option hidden disabled value="" />}
          {props.availableTreatments.includes('ALLO_TX') && <option value="ALLO_TX">{t('edit.treatmentOptions.alloTx')}</option>}
          {props.availableTreatments.includes('CAR_T') && <option value="CAR_T">{t('edit.treatmentOptions.carT')}</option>}
        </select>
        <label className="textHeader">{t('edit.name')}</label>
        <input
          className="editFormInput"
          id="name"
          pattern="[a-zA-ZöäüÖÄÜß \-]*"
          title={t('edit.only_letters')}
          type="text"
          placeholder={props.currentPatient.name}
          name="name"
          onChange={onInputValueChange}
          required={!props.editMode}
          data-testid={e2eTestIds.patientForm.firstname}
        />
        <label className="textHeader">{t('edit.surname')}</label>
        <input
          className="editFormInput"
          id="surname"
          pattern="[a-zA-ZöäüÖÄÜß \-]*"
          title={t('edit.only_letters')}
          type="text"
          placeholder={props.currentPatient.surname}
          name="surname"
          onChange={onInputValueChange}
          required={!props.editMode}
          data-testid={e2eTestIds.patientForm.surname}
        />
        <label className="textHeader">{t('edit.birthday')}</label>
        <input
          className="editFormInput"
          id="birthday"
          pattern="\d{2}.\d{2}.\d{4}"
          title={t('edit.date_format')}
          type="text"
          placeholder={props.currentPatient.birthday}
          name="birthday"
          onChange={onInputValueChange}
          data-testid={e2eTestIds.patientForm.birthday}
        />
        <label className="textHeader">{t('edit.sex')}</label>
        <select
          className="editDropdown"
          id="sex"
          name="sex"
          onChange={onInputValueChange}
          defaultValue={props.currentPatient.sex ?? ''}
          data-testid={e2eTestIds.patientForm.sex}
        >
          <option hidden disabled value="" />
          <option value="female">{t('edit.female')}</option>
          <option value="male">{t('edit.male')}</option>
          <option value="X">{t('edit.diverse')}</option>
        </select>
        <label className="textHeader">{t('edit.phone')}</label>
        <input
          className="editFormInput"
          id="phone"
          pattern="0[ ()]?([- ()]?\d[- ()]?){6,15}" // todo: this pattern is actually invalid and not used
          title={t('edit.phone_format')}
          type="text"
          placeholder={props.currentPatient.phone}
          name="phone"
          onChange={onInputValueChange}
          required={!props.editMode}
          data-testid={e2eTestIds.patientForm.phone}
        />
        <label className="textHeader">{t('edit.street')}</label>
        <input
          className="editFormInput"
          id="street"
          pattern="[a-zA-ZöäüÖÄÜß.\d \-]*"
          title={t('edit.only_letters_and_numbers')}
          type="text"
          placeholder={props.currentPatient.street}
          name="street"
          onChange={onInputValueChange}
          data-testid={e2eTestIds.patientForm.street}
        />
        <label className="textHeader">{t('edit.zipcode')}</label>
        <input
          className="editFormInput"
          id="postcode"
          pattern="\d{3,5}"
          title={t('edit.zipcode_format')}
          type="text"
          placeholder={props.currentPatient.postcode}
          name="postcode"
          onChange={onInputValueChange}
          data-testid={e2eTestIds.patientForm.postcode}
        />
        <label className="textHeader">{t('edit.city')}</label>
        <input
          className="editFormInput"
          id="city"
          pattern="[a-zA-ZöäüÖÄÜß \-]*"
          title={t('edit.only_letters')}
          type="text"
          placeholder={props.currentPatient.city}
          name="city"
          onChange={onInputValueChange}
          required={!props.editMode}
          data-testid={e2eTestIds.patientForm.city}
        />
      </div>
      <div className="buttonContainer">
        {props.onCancel && (
          <button className="buttonCancel" onClick={props.onCancel}>
            {props.cancelLabel}
          </button>
        )}
        <button
          className="buttonSave"
          type="submit"
          disabled={props.submitting}
          data-testid={e2eTestIds.patientForm.submit}
        >
          {props.submitLabel}
        </button>
      </div>
    </form>
  );
}

PatientInformationForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string,
  editFeedback: PropTypes.string.isRequired,
  setEditFeedback: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentPatient: PropTypes.object.isRequired,
  patientAlreadyExists: PropTypes.bool,
  resetPatientAlreadyExists: PropTypes.func,
  submitting: PropTypes.bool,
  editMode: PropTypes.bool,
  availableTreatments: TreatmentOptions.isRequired,
};

PatientInformationForm.defaultProps = {
  onCancel: undefined,
  cancelLabel: undefined,
  submitting: false,
  editMode: false,
  patientAlreadyExists: false,
  resetPatientAlreadyExists: () => {},
};

export default withTranslation('common')(PatientInformationForm);
