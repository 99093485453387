import React from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import PropTypes from 'prop-types';
import './ScalarValuePieChart.scss';

const ScalarValuePieChart = (props) => {
  const {
    value, title,
  } = props;

  return (
    <PieChart
      className="pie-chart on-hover-blue"
      data={[
        {
          title,
          value: 10 - value,
          color: '#eeeeee',
        },
        {
          title,
          value,
          color: '#545454',
        },
      ]}
      startAngle={0}
      paddingAngle={0}
    />
  );
};


ScalarValuePieChart.propTypes = {
  value: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};


export default ScalarValuePieChart;
