import PropTypes from 'prop-types';
import React from 'react';
import './Error.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { faFrown } from '@fortawesome/free-regular-svg-icons/faFrown';

/**
 * This component is for failing cases, it dispylays an forwning smiley and
 * an corresponding Errorcode
 * @props code Errorcode, needed for mapping the error
 * @hideconstructor
 * @returns {function} ReactElement ErrorMessage incl. ErrorCode
 */
const Error = (props) => {
  const { code, hint, t } = props;
  const errorMessage = t('error.error_message');
  const errorLabel = `${errorMessage} - ${code}`;

  return (
    <div className="error col-xs-12">
      <p className="error-label">{errorLabel}</p>
      <p className="error-icon"><FontAwesomeIcon size="5x" icon={faFrown}/></p>
      <p className="error-label">{hint}</p>
    </div>
  );
};

export default withTranslation('common')(Error);

Error.propTypes = {
  code: PropTypes.number,
  hint: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Error.defaultProps = {
  code: 404,
  hint: '',
};
