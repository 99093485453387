import React from 'react';
import { Link, useMatches } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import './HeaderContainer.scss';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const HeaderContainer = (props) => {
  const matches = useMatches();

  const backButtonHandle = matches.find(
    (match) => match.handle?.subheader !== undefined,
  )?.handle.backButtonPath;

  // todo: clean this up, this is not very stable!
  // eslint-disable-next-line no-restricted-globals
  const backPath =
    backButtonHandle === '..'
      ? // eslint-disable-next-line no-restricted-globals
      location.href.split('/').slice(0, -1).join('/')
      : '/';

  return (
    <div className="container-fluid headerContainer">
      <div className="row center-row-content">
        <Link to={backPath} className="no-print" relative="path" onClick={props.callback}>
          <span className="arrowBack">
            <FontAwesomeIcon size="1x" icon={faArrowLeft}/>
          </span>
        </Link>
        {props.children}
      </div>
    </div>
  );
};
HeaderContainer.propTypes = {
  children: PropTypes.element,
  callback: PropTypes.func,
};

HeaderContainer.defaultProps = {
  children: <div/>,
  callback: () => {},
};

export default HeaderContainer;
