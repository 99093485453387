import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import '../PatientCardHeader.scss';
import ValueToolTip from '../../../../../../common/value-tool-tip/ValueToolTip';

const ValueDisplay = (props) => {

  const { valueArr, unit, tooltipTitle } = props;

  const tooltipId = uuidv4();
  const value = valueArr && valueArr[0] ? valueArr[0].value : '--';
  const timestamp = valueArr && valueArr[0] ? valueArr[0].timestamp : undefined;
  return (
    <span className="on-hover-blue" data-for={tooltipId} data-tip>
      <span className="value-style" data-testid={props['data-testid']}>
        { value }&nbsp;
      </span>
      <span className="unit-style">
        { unit }
      </span>
      <ValueToolTip tooltipId={tooltipId} name={tooltipTitle} timestamp={timestamp || '--'} formatDate={!!timestamp}/>
    </span>
  );
};

export default ValueDisplay;

ValueDisplay.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  valueArr: PropTypes.array.isRequired,
  unit: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.string.isRequired,
  'data-testid': PropTypes.string,
};

ValueDisplay.defaultProps = {
  'data-testid': undefined,
};
