function patch(overwrite, autoEvaluation) {
  return overwrite !== null ? overwrite : autoEvaluation;
}

function patchData(rawData, overwrite) {
  if (!overwrite) {
    overwrite = {
      nameImages: [null, null, null],
      following: null,
      concentration: null,
      handwriting: null,
      orientation: {
        year: null,
        month: null,
        city: null,
        hospital: null,
      },
    };
  }

  return {
    nameImages: rawData.nameImages.map((entry, index) => ({
      ...entry,
      accepted: patch(overwrite.nameImages[index], entry.accepted),
    })),
    following: {
      ...rawData.following,
      accepted: patch(overwrite.following, rawData.following.accepted),
    },
    concentration: {
      ...rawData.concentration,
      accepted: patch(
        overwrite.concentration,
        rawData.concentration.accepted,
      ),
    },
    handwriting: {
      ...rawData.handwriting,
      accepted: patch(overwrite.handwriting, rawData.handwriting.accepted),
    },
    orientation: {
      year: {
        ...rawData.orientation.year,
        accepted: patch(
          overwrite.orientation.year,
          rawData.orientation.year.accepted,
        ),
      },
      month: {
        ...rawData.orientation.month,
        accepted: patch(
          overwrite.orientation.month,
          rawData.orientation.month.accepted,
        ),
      },
      city: {
        ...rawData.orientation.city,
        accepted: patch(
          overwrite.orientation.city,
          rawData.orientation.city.accepted,
        ),
      },
      hospital: {
        ...rawData.orientation.hospital,
        accepted: patch(
          overwrite.orientation.hospital,
          rawData.orientation.hospital.accepted,
        ),
      },
    },
  };
}

function calculateQuestionnaireScore(patient, date) {
  const questionnaireEvaluation = patient.questionnaires.find(q => q.date === date);
  const matchingOverwrite = patient.overwrites.find(q => q.date === date);

  const patchedData = patchData(questionnaireEvaluation, matchingOverwrite);

  const flatEvaluationResults = [
    ...Object.values(patchedData.orientation).map((entry) => entry.accepted),
    ...patchedData.nameImages.map((entry) => entry.accepted),
    patchedData.concentration.accepted,
    patchedData.handwriting.accepted,
    patchedData.following.accepted,
  ];

  return flatEvaluationResults.some(evaluation => evaluation === null)
    ? null
    : flatEvaluationResults.map(bool => (bool ? 1 : 0)).reduce((acc, val) => acc + val, 0);
}


export { patchData, calculateQuestionnaireScore };

