import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

/**
 * Component which render Loading Spinner Component or passed Component
 * depending on the isLoading prop
 * @param {Component} Component
 * @returns {Component} React Component
 */
const withLoadingNotification = (Component) => {

  const Sub = ({
    isLoading, count, children, ...rest
  }) => {
    if (isLoading) {
      return (
        <div className="loading-container">
          <Skeleton className="loading-style" count={count} duration={1}/>
        </div>
      );
    }
    return (
      <Component {...rest}>
        {children}
      </Component>
    );
  };

  Sub.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,
    count: PropTypes.number,
  };

  Sub.defaultProps = {
    count: 1,
  };

  return Sub;
};

export default withLoadingNotification;
