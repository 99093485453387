import errors from '../../errors';
import ApiService from '../apiService';


class PatientCheckedService {

  constructor() {
    this.patientCheckedEndpoint = process.env.REACT_APP_SINGLE_PATIENT_IS_CHECKED_ENDPOINT;
    this.patientCheckEndpoint = process.env.REACT_APP_SINGLE_PATIENT_CHECK_ENDPOINT;
    this.patientUncheckEndpoint = process.env.REACT_APP_SINGLE_PATIENT_UNCHECK_ENDPOINT;
  }

  /**
   * Try to request if a patient is checked
   * @param {number} id
   * @returns {Promise} isPatientChecked
   */
  async getPatientChecked(id) {
    // throw error if the id is null or undefined
    if (!id) {
      return Promise.reject(new Error(errors.noIdErr));
    }

    const endpoint = this.patientCheckedEndpoint.replace('%id', id);
    return ApiService.getData(endpoint)
      .then((res) => {
        if (!res.data) return Promise.reject(new Error(errors.invalidPatientDetailsResponseErr));
        return Promise.resolve(res.data);
      })
      .catch(() => Promise.reject(new Error(`${errors.noPatientDetailsResponseErr} ${id}`)));
  }

  async setPatientChecked(id) {
    // throw error if the id is null or undefined
    if (!id) {
      return Promise.reject(new Error(errors.noIdErr));
    }

    const endpoint = this.patientCheckEndpoint.replace('%id', id);
    return ApiService.getData(endpoint)
      .catch(() => Promise.reject(new Error(`${errors.noPatientDetailsResponseErr} ${id}`)));
  }

  async setPatientUnchecked(id) {
    // throw error if the id is null or undefined
    if (!id) {
      return Promise.reject(new Error(errors.noIdErr));
    }

    const endpoint = this.patientUncheckEndpoint.replace('%id', id);
    return ApiService.getData(endpoint)
      .catch(() => Promise.reject(new Error(`${errors.noPatientDetailsResponseErr} ${id}`)));
  }
}

export default new PatientCheckedService();
