import { createSlice } from '@reduxjs/toolkit';
import helpers from '../helpers/staticHelperMethods';


export const patientHeaderSlice = createSlice({
  name: 'patientHeaderStore',
  initialState: {
    fromDate: helpers.calculateDateWeeksAgo(1).getTime(),
    toDate: helpers.getLatestDateToday(),
  },
  reducers: {
    updateTimespan: (state, action) => {
      state.fromDate = action.payload.fromDate;
      state.toDate = action.payload.toDate;
    },
  },
});

export const { updateTimespan } = patientHeaderSlice.actions;

export default patientHeaderSlice.reducer;
