import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import licenses from '../../assets/licenses.txt';
import './Licenses.scoped.scss';

function Licenses(props) {
  const { t } = props;

  const [licenseText, setLicenseText] = useState(t('licenses.loading'));

  useEffect(() => {
    fetch(licenses)
      .then((res) => res.text())
      .then((text) => setLicenseText(text));
  });

  return (
    <>
      <a className="license-back-button" href="/">
        {t('licenses.backButton')}
      </a>
      <div
        style={{
          textAlign: 'left',
          margin: '2% 5%',
        }}
      >
        <h1>{t('licenses.title')}</h1>
        <p className="license-text">{licenseText}</p>
      </div>
    </>
  );
}

Licenses.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(Licenses);
