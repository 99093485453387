import React from 'react';
import PropTypes from 'prop-types';
import 'react-loading-skeleton/dist/skeleton.css';
import { withTranslation } from 'react-i18next';
import Row from './row/Row';
import patientShape from '../../../prop-types/patient';
import valueLimitsShape from '../../../prop-types/valueLimits';
import './PatientsGrid.scss';

/**
 * Displays the grid of patient data
 * @returns {function} ReactElement represents Rows
 * (amount equals patient inside filteredPatients) each
 * containing latest information about one Patient (Name, Age, Vitalparams and Symptoms)
 * @see Row
 * @example <PatientsGrid patients={filteredPatients} valueLimits={valueLimits}/>
 * @class
 * @hideconstructor
 * @param props
 */

function PatientsGrid(props) {
  const { t, valueLimits, filteredPatients } = props;

  const rows = filteredPatients.map(patient => (
    <Row
      key={patient.id}
      patient={patient}
      valueLimits={valueLimits}
    />));

  return (
    <div className="patientsgrid">
      {filteredPatients.length ?
        rows
        : (
          <div className="filterNoResultOuter">
            <div className="filterNoResultInner">
              {t('filterBar.noResult')}
            </div>
          </div>
        )}
    </div>
  );
}

export default withTranslation('common')(PatientsGrid);

PatientsGrid.propTypes = {
  t: PropTypes.func.isRequired,
  valueLimits: valueLimitsShape,
  filteredPatients: PropTypes.arrayOf(patientShape),
};

PatientsGrid.defaultProps = {
  valueLimits: {},
  filteredPatients: [],
};
