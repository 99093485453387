import { useKeycloak } from '@react-keycloak/web';

function useAuth() {
  const { keycloak, initialized } = useKeycloak();

  return {
    isInitialized: initialized,
    isLoggedIn: keycloak.authenticated,
    login: keycloak.login,
    logout: keycloak.logout,
    refreshToken: keycloak.updateToken,
    user: keycloak.authenticated ? {
      username: keycloak.tokenParsed?.preferred_username,
    } : undefined,
  };
}

export default useAuth;
