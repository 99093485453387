import React from 'react';
import {
  createBrowserRouter, createRoutesFromElements, Navigate, Outlet, Route, RouterProvider,
} from 'react-router-dom';
import './App.scss';
import Layout from './components/layout/Layout';
import Dashboard from './components/dashboard/Dashboard';
import PatientDetails from './components/patient-details/PatientDetails';
import EditComponent from './components/edit/EditComponent';
import InviteComponent from './components/invite/InviteComponent';
import Error from './components/error/Error';
import QuestionnaireEvaluation from './components/questionnaire-evaluation/QuestionnaireEvaluation';
import QuestionnaireEvaluationOverview from './components/questionnaire-evaluation/QuestionnaireEvaluationOverview';
import PatientCommentOverview from './components/patient-comment-overview/PatientCommentOverview';
import AuthGuard from './components/auth/AuthGuard';
import Licenses from './components/licenses/Licenses';
import useFeatureFlags from './components/feature-flags/useFeatureFlags';

const App = () => {
  const { patientCommentsActive } = useFeatureFlags();
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route element={<AuthGuard />}>
          <Route element={<Layout/>}>
            <Route
              id={0}
              path="/"
              element={<Dashboard/>}
              name="Dashboard"
              handle={{
                subheader: 'dashboard',
              }}
            />
            <Route path="/patient/">
              <Route path=":patientId">
                <Route
                  id={7}
                  element={patientCommentsActive ? <PatientCommentOverview/> : <Navigate to="/"/>}
                  path="comments"
                  name="Comments"
                  handle={{
                    subheader: 'comments',
                  }}
                />
                <Route
                  id={1}
                  path="details"
                  element={<PatientDetails/>}
                  name="Details"
                  handle={{
                    subheader: 'patient',
                  }}
                />
                <Route path="questionnaire" element={<Outlet/>}>
                  <Route
                    id={5}
                    element={<QuestionnaireEvaluationOverview/>}
                    index
                    name="Questionnaire Overview"
                    handle={{
                      subheader: 'questionnaireOverview',
                    }}
                  />
                  <Route
                    id={6}
                    path=":questionnaireDate"
                    element={<QuestionnaireEvaluation/>}
                    name="Questionnaire"
                    handle={{
                      subheader: 'questionnaire',
                      backButtonPath: '..',
                    }}
                  />
                </Route>
              </Route>
            </Route>
            <Route
              id={2}
              path="/edit/:studyNumber"
              element={<EditComponent/>}
              name="Edit"
              handle={{
                subheader: 'edit',
              }}
            />
            <Route
              id={3}
              path="/inviteUser"
              element={<InviteComponent/>}
              name="Invite"
              handle={{
                subheader: 'inviteUser',
              }}
            />
            <Route id={4} path="*" element={<Error/>} name="Error"/>
          </Route>
        </Route>
        <Route path="/licenses" element={<Licenses />}/>
      </Route >,
    ),
  );

  return (
    <RouterProvider router={router}/>
  );
};

export default App;
