import PropTypes from 'prop-types';

const valueLimitDefinition = PropTypes.shape({
  inputType: PropTypes.string.isRequired,
  max: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }),
  min: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }),
});

export default valueLimitDefinition;
