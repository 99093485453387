import DropdownButton from 'react-bootstrap/DropdownButton';
import DropdownItem from 'react-bootstrap/DropdownItem';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import './TimespanDropdown.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { updateTimespan } from '../../../../redux/patientHeaderSlice';
import helpers from '../../../../helpers/staticHelperMethods';

const TimespanDropdown = (props) => {

  const { t } = props;

  const dispatch = useDispatch();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const fromDate = useSelector(state => state.patientHeader.fromDate);
  const toDate = useSelector(state => state.patientHeader.toDate);

  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);

  const [selectedTimespanOption, setSelectedTimespanOption] = useState('oneWeek');

  /**
   * Mapping of string options to numbers
   * @param weeksAgoString
   * @returns {number}
   */
  const timeSpanInt = (weeksAgoString) => {
    switch (weeksAgoString) {
    case 'oneWeek':
      return 1;
    case 'thirtyDays':
      return 4;
    case 'ninetyDays':
      return 12;
    case 'halfYear':
      return 24;
    case 'oneYear':
      return 52;
    default:
      return 1;
    }
  };

  /**
   * This method handles the situation, when a user selects a new period of time.
   * @param option, is the period of time
   */
  const handlePeriodOfTimeChange = (option) => {
    setSelectedTimespanOption(option);
    const updatedFromDate = helpers.calculateDateWeeksAgo(timeSpanInt(option)).getTime();
    const updatedToDate = helpers.getLatestDateToday();
    dispatch(updateTimespan({
      fromDate: updatedFromDate,
      toDate: updatedToDate,
    }));
    setStartDate(updatedFromDate);
    setEndDate(updatedToDate);
  };

  /**
   * This method stores the custom time period selected by the user in the store.
   */
  const setCustomFromAndToDate = () => {
    setSelectedTimespanOption('custom');
    dispatch(updateTimespan({
      fromDate: startDate,
      toDate: endDate,
    }));
    setModalIsOpen(false);
  };

  useEffect(() => {
    handlePeriodOfTimeChange('oneWeek');
  }, []);

  return (
    <span>
      <Modal
        show={modalIsOpen}
        centered
      >
        <Modal.Dialog>
          <Modal.Title>
            <FontAwesomeIcon icon={faCalendarDay} size="sm"/> {t('patientHeader.timeChooser')}
          </Modal.Title>
        </Modal.Dialog>
        <Modal.Body>
          <div className="container-fluid background-white">
            <div className="row margin-bottom">
              <div className="col-2">
                {t('patientHeader.fromDate')}
              </div>
              <div className="col-10">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date.getTime())}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat={t('patientHeader.dateFormat')}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-2">
                {t('patientHeader.toDate')}
              </div>
              <div className="col-10">
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date.getTime())}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat={t('patientHeader.dateFormat')}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="buttonSave"
            onClick={setCustomFromAndToDate}
          >
            {t('patientHeader.apply')}
          </button>
          <button
            className="buttonModalCancel"
            onClick={() => setModalIsOpen(false)}
          >
            {t('patientHeader.closeDatePicker')}
          </button>
        </Modal.Footer>
      </Modal>
      <DropdownButton
        id="timespan"
        title={t('patientHeader.dropDownMenu') + t(`patientHeader.${selectedTimespanOption}`)}
        className="patientHeaderSortDropdown"
      >
        <DropdownItem
          active
          id="newData"
          eventKey={1}
          onClick={() => {
            handlePeriodOfTimeChange('oneWeek');
          }}
        >{t('patientHeader.oneWeek')}
        </DropdownItem>
        <DropdownItem
          active
          id="transplantDate"
          eventKey={2}
          onClick={() => {
            handlePeriodOfTimeChange('thirtyDays');
          }}
        >{t('patientHeader.thirtyDays')}
        </DropdownItem>
        <DropdownItem
          active
          id="name"
          eventKey={3}
          onClick={() => {
            handlePeriodOfTimeChange('ninetyDays');
          }}
        >{t('patientHeader.ninetyDays')}
        </DropdownItem>
        <DropdownItem
          active
          id="name"
          eventKey={3}
          onClick={() => {
            handlePeriodOfTimeChange('halfYear');
          }}
        >{t('patientHeader.halfYear')}
        </DropdownItem>
        <DropdownItem
          active
          id="name"
          eventKey={3}
          onClick={() => {
            handlePeriodOfTimeChange('oneYear');
          }}
        >{t('patientHeader.oneYear')}
        </DropdownItem>
        <DropdownItem
          active
          id="name"
          eventKey={3}
          onClick={() => {
            setModalIsOpen(true);
          }}
        >{t('patientHeader.custom')}
        </DropdownItem>
      </DropdownButton>
    </span>
  );
};

TimespanDropdown.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(TimespanDropdown);
