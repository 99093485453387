import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './HandWritingImage.scoped.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRotateLeft, faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';


const HandWritingImage = (props) => {

  const {
    t,
    imageSrc,
  } = props;

  const [currentImageRotation, setCurrentRotation] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);

  const addRotation = (deg) => {
    setCurrentRotation(deg + currentImageRotation);
  };

  return (
    <div className="container">
      <div className="handWritingImageContainer">
        <img
          src={imageSrc}
          onLoad={() => setImageLoaded(true)}
          onError={({ currentTarget }) => {
            // prevent loops
            currentTarget.onerror = null;
            currentTarget.style.display = 'none';
          }}
          alt=""
          className="handWritingImage"
          style={{ rotate: `${currentImageRotation}deg` }}
        />
        {!imageLoaded &&
          <div className="handWritingImageErrorText">{t('questionnaire.handWriting.no_image_available')}</div>}
      </div>
      {imageLoaded && (
        <div className="handWritingImageRotationButtonContainer">
          <button
            type="button"
            className="handWritingImageRotationButton"
            onClick={() => addRotation(-90)}
          >
            <FontAwesomeIcon
              icon={faArrowRotateLeft}
              size="2x"
            />
            &nbsp;
          </button>
          <button
            type="button"
            className="handWritingImageRotationButton"
            onClick={() => addRotation(90)}
          >
            <FontAwesomeIcon
              icon={faArrowRotateRight}
              size="2x"
            />
            &nbsp;
          </button>
        </div>
      )}
    </div>);
};

export default withTranslation('common')(HandWritingImage);

HandWritingImage.propTypes = {
  t: PropTypes.func.isRequired,
  imageSrc: PropTypes.string.isRequired,
};
