import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiService from '../services/apiService';

export const fetchValueConfig = createAsyncThunk('valueConfig/fetchValueConfig', async () => {
  const valueConfigResponse = await ApiService.getData(process.env.REACT_APP_VALUE_LIMITS_ENDPOINT);
  return valueConfigResponse.data;
});

export const valueConfigSlice = createSlice({
  name: 'valueConfigStore',
  initialState: {
    valueConfig: undefined,
    status: 'idle',
    error: undefined,
  },
  extraReducers(builder) {
    builder
      .addCase(fetchValueConfig.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchValueConfig.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.valueConfig = action.payload;
      })
      .addCase(fetchValueConfig.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});
export default valueConfigSlice.reducer;
