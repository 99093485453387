import PropTypes from 'prop-types';
import SymptomShape from './symptom';
import vitalPair from './vitalPair';
import vitalPairBloodPressure from './vitalPairBloodPressure';
import valueAndTimestampShape from './valueAndTimestampShape';
import Treatment from './treatment';
import PatientCommentShape from './patientComment';

const patientShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  study_number: PropTypes.string.isRequired,
  name: PropTypes.string,
  surname: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  postcode: PropTypes.string,
  phone: PropTypes.string,
  birthday: PropTypes.string,
  sex: PropTypes.oneOf(['male', 'female', 'X', '']),
  transplant_date: PropTypes.string.isRequired,
  temperature: PropTypes.arrayOf(vitalPair).isRequired,
  weight: PropTypes.arrayOf(vitalPair).isRequired,
  wellBeing: PropTypes.arrayOf(valueAndTimestampShape).isRequired,
  blood_pressure: PropTypes.arrayOf(vitalPairBloodPressure).isRequired,
  symptoms: PropTypes.arrayOf(SymptomShape).isRequired,
  treatment: Treatment.isRequired,
  patientComments: PatientCommentShape.isRequired,
});

export default patientShape;
