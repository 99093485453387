import DropdownButton from 'react-bootstrap/DropdownButton';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDownWideShort,
  faArrowUpShortWide,
  faFileCircleExclamation,
  faFilter,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import './DashboardHeader.scss';
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useSelector } from 'react-redux';
import FilterBar from '../../dashboard/filter-bar/FilterBar';
import e2eTestIds from '../../e2eTestIds';

const scroll = Scroll.animateScroll;

const DashboardHeader = (props) => {
  const {
    t, currentSort, ascendingSort, onOrderButtonClick,
    onSortSelected, onFilterTextChange, onNewDataButtonClick,
    onTreatmentFilterSelected, currentTreatmentFilter,
  } = props;

  const isNewData = useSelector(state => state.newPatientData.newPatientData);

  return (
    <div className="dashboardHeader">
      <div className="dashboardHeaderContainer">
        <div className="dashboardOperationContainer">
          <Button className="dashboardSortDirectionButton" variant="secondary" onClick={onOrderButtonClick}>
            <FontAwesomeIcon icon={!ascendingSort ? faArrowDownWideShort : faArrowUpShortWide}/>
          </Button>
          <DropdownButton
            title={t('dashboard.dropdownMenu') + t(`dashboard.${currentSort}`)}
            className="dashboardDropdown"
          >
            <DropdownItem
              active
              id="newData"
              eventKey={1}
              onClick={() => {
                onSortSelected('newData');
                scroll.scrollToTop();
              }}
            >
              {t('dashboard.newData')}
            </DropdownItem>
            <DropdownItem
              active
              id="transplantDate"
              eventKey={2}
              onClick={() => {
                onSortSelected('transplantDate');
                scroll.scrollToTop();
              }}
            >{t('dashboard.transplantDate')}
            </DropdownItem>
            <DropdownItem
              active
              id="name"
              eventKey={3}
              onClick={() => {
                onSortSelected('name');
                scroll.scrollToTop();
              }}
            >
              {t('dashboard.name')}
            </DropdownItem>
          </DropdownButton>
        </div>
        <div className="dashboardOperationContainer">
          <FontAwesomeIcon icon={faFilter} className="treatmentFilterIcon"/>
          <DropdownButton
            title={t('dashboard.filterTreatmentMenu') + t(`dashboard.${currentTreatmentFilter}`)}
            className="dashboardDropdown"
          >
            <DropdownItem
              active
              id="allTreatments"
              eventKey={1}
              onClick={() => {
                onTreatmentFilterSelected('allTreatments');
                scroll.scrollToTop();
              }}
            >
              {t('dashboard.allTreatments')}
            </DropdownItem>
            <DropdownItem
              active
              id="carT"
              eventKey={2}
              onClick={() => {
                onTreatmentFilterSelected('carT');
                scroll.scrollToTop();
              }}
            >
              {t('dashboard.carT')}
            </DropdownItem>
            <DropdownItem
              active
              id="alloTx"
              eventKey={3}
              onClick={() => {
                onTreatmentFilterSelected('alloTx');
                scroll.scrollToTop();
              }}
            >{t('dashboard.alloTx')}
            </DropdownItem>
          </DropdownButton>
        </div>
      </div>
      <div className="dashboardHeaderContainer">
        {isNewData &&
          <Button
            className="btn-light newDataButton"
            onClick={onNewDataButtonClick}
          >
            <FontAwesomeIcon size="xl" icon={faFileCircleExclamation}/>
            <span className="newDataToastSpan">{t('toasts.newPatientData')}</span>
          </Button>
        }
      </div>
      <div className="dashboardHeaderContainer">
        <FilterBar onFilterTextChange={onFilterTextChange}/>
        <Link className="inviteLink" to="/inviteUser" data-testid={e2eTestIds.subHeader.dashboard.inviteLink}>
          <button className="inviteButton btn"><FontAwesomeIcon icon={faUserPlus}/></button>
        </Link>
      </div>
    </div>
  );
};

DashboardHeader.propTypes = {
  t: PropTypes.func.isRequired,
  currentSort: PropTypes.string.isRequired,
  ascendingSort: PropTypes.bool.isRequired,
  onSortSelected: PropTypes.func.isRequired,
  onOrderButtonClick: PropTypes.func.isRequired,
  onFilterTextChange: PropTypes.func.isRequired,
  onNewDataButtonClick: PropTypes.func.isRequired,
  onTreatmentFilterSelected: PropTypes.func.isRequired,
  currentTreatmentFilter: PropTypes.string.isRequired,
};

export default withTranslation('common')(DashboardHeader);
