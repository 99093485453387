import React from 'react';
import PropTypes from 'prop-types';
import './Labels.scss';
import { withTranslation } from 'react-i18next';

/** @classdesc creates labels for the bars
 * @see '../bar/Bar.js'
 * @param {number} props.currentValue Value that is displayed (between min-max)
 * (needed to calculate position of label)
 * @returns {function}  JSX Code that renders labels that will be displayed next to bar
 * @hideconstructor
 * @class
 */
const labels = (props) => {
  const {
    t,
    currentValue,
    isBinary,
  } = props;


  const className = `Labels ${currentValue < 10 && !isBinary ? 'LabelsLarge' : ''} ${isBinary ? 'binaryLabel' : ''}`;
  const binaryAnswer = currentValue === 0 ? t('dashboard.binarySymptomNo') : t('dashboard.binarySymptomYes');
  return (
    <div className={className} data-testid={props['data-testid']}>
      {isBinary ? binaryAnswer : currentValue}
    </div>
  );
};

export default withTranslation('common')(labels);

labels.propTypes = {
  currentValue: PropTypes.number.isRequired,
  isBinary: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  'data-testid': PropTypes.string,
};

labels.defaultProps = {
  'data-testid': undefined,
};
