import React from 'react';
import patientShape from '../../../../prop-types/patient';
import valueLimitsShape from '../../../../prop-types/valueLimits';
import './Row.scss';
import PatientCard from './patient-card/PatientCard';

/**
 * @function Displays a flex grid row for one patient
 */
function Row(props) {

  const { patient, valueLimits } = props;

  return (
    <div>
      <div className="row">
        <PatientCard
          patient={patient}
          valueLimits={valueLimits}
        />
      </div>
    </div>
  );
}

Row.propTypes = {
  patient: patientShape.isRequired,
  valueLimits: valueLimitsShape.isRequired,
};
export default Row;
