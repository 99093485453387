import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import '../PatientCardHeader.scss';
import { v4 as uuidv4 } from 'uuid';
import valueAndTimestampShape from '../../../../../../../prop-types/valueAndTimestampShape';
import ValueToolTip from '../../../../../../common/value-tool-tip/ValueToolTip';
import ScalarValuePieChart from '../../../../../../common/scalar-value-pie-chart/ScalarValuePieChart';
import '../../../../../../../globalStyles/globalStyles.scss';
import e2eTestIds from '../../../../../../e2eTestIds';

const WellBeing = (props) => {

  const { valueAndTimestamp, t } = props;
  const tooltipId = uuidv4();
  const label = t('wellbeing.wellbeing');
  const title = t('title.wellBeing');
  const noDataPlaceHolder = t('title.no_data_placeholder');

  let displayElement;
  if (valueAndTimestamp === null) {
    displayElement = noDataPlaceHolder;
  } else if (valueAndTimestamp.value === 0) {
    displayElement = t('wellbeing.skipped');
  } else {
    displayElement = valueAndTimestamp.value;
  }

  return (
    <div className="scalar-value" data-tip data-for={tooltipId}>
      <span className="scalar-value-label-style on-hover-blue">
        {label}
        &nbsp;
        <span className="scalar-value-value-style" data-testid={e2eTestIds.dashboard.patientCard.wellBeing}>
          {displayElement}
        </span>
      </span>
      {
        valueAndTimestamp?.value > 0 &&
        <ScalarValuePieChart value={valueAndTimestamp.value} title={title}/>
      }
      <ValueToolTip
        tooltipId={tooltipId}
        name={title}
        timestamp={valueAndTimestamp?.updatedAt}
        showTimeInTimestamp
        showYearInTimestamp
        showPlaceholder={valueAndTimestamp === null}
      />
    </div>
  );
};


export default withTranslation('common')(WellBeing);

WellBeing.propTypes = {
  valueAndTimestamp: valueAndTimestampShape,
  t: PropTypes.func.isRequired,
};

WellBeing.defaultProps = {
  valueAndTimestamp: null,
};
