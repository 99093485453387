import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useMatches } from 'react-router-dom';
import EditHeader from './edit-header/EditHeader';
import PatientHeader from './patient-header/PatientHeader';
import DashboardHeader from './dashboard-header/DashboardHeader';
import './PageHeader.scss';
import InviteHeader from './invite-header/InviteHeader';
import QuestionnaireHeader from './questionnaire-header/QuestionnaireHeader';
import CommentsHeader from './comments-header/CommentsHeader';

function PageHeader(props) {
  const { t } = props;

  const matches = useMatches();

  const subHeaderHandle = matches.find(
    (match) => match.handle?.subheader !== undefined,
  )?.handle.subheader;

  const [editPageData, setEditPageData] = useState(undefined);
  const [patientDetailData, setPatientDetailData] = useState(undefined);
  const [dashboardData, setDashboardData] = useState(undefined);

  function onEditPageEvent(event) {
    setEditPageData(event.detail.currentPatient);
  }

  function onPatientDetailPageEvent(event) {
    if (event.detail) {
      setPatientDetailData(event.detail);
    } else {
      // set the header anyway. Is also useful for the error page
      setPatientDetailData({});
    }
  }

  function onDashboardPageEvent(event) {
    setDashboardData(event.detail);
  }

  useEffect(() => {
    document.addEventListener('editPageEvent', (event) => {
      onEditPageEvent(event);
    });
    document.addEventListener('patientDetailPageEvent', (event) => {
      onPatientDetailPageEvent(event);
    });
    document.addEventListener('dashboardPageEvent', (event) => {
      onDashboardPageEvent(event);
    });
  }, []);

  let subHeader;
  if (subHeaderHandle === 'edit' && editPageData) {
    subHeader = (
      <EditHeader name={editPageData.name} surname={editPageData.surname}/>
    );
  } else if (subHeaderHandle === 'patient' && patientDetailData) {
    subHeader = <PatientHeader patientDetailData={patientDetailData}/>;
  } else if ((subHeaderHandle === 'questionnaire' || subHeaderHandle === 'questionnaireOverview') && patientDetailData) {
    subHeader = <QuestionnaireHeader patientDetailData={patientDetailData}/>;
  } else if (subHeaderHandle === 'comments' && patientDetailData) {
    subHeader = <CommentsHeader patientDetailData={patientDetailData}/>;
  } else if (subHeaderHandle === 'inviteUser') {
    subHeader = <InviteHeader/>;
  } else if (subHeaderHandle === 'dashboard' && dashboardData) {
    subHeader = (
      <DashboardHeader
        onSortSelected={dashboardData.handleSortSelection}
        onOrderButtonClick={dashboardData.handleOrderButtonClick}
        ascendingSort={dashboardData.sorted.ascending}
        currentSort={dashboardData.sorted.type}
        onFilterTextChange={dashboardData.handleFilterTextChange}
        onNewDataButtonClick={dashboardData.onNewDataButtonClick}
        onTreatmentFilterSelected={dashboardData.handleTreatmentFilterSelection}
        currentTreatmentFilter={dashboardData.treatmentFilter.treatment}
        t={t}
      />
    );
  } else {
    subHeader = undefined;
  }
  return <div>{subHeader}</div>;
}


PageHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(PageHeader);
