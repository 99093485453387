import axios from 'axios';
import errors from '../errors';

// const instance = axios.create();
// instance.interceptors.request.use(AxiosLogger.requestLogger);
// instance.interceptors.response.use(AxiosLogger.responseLogger);
/**
 * Containing the main functionality of http requests
 * All outgoing requests are using this implementation
 * @class
 */

const config = {
  crossdomain: true,
};

class ApiService {

  /**
   * Standard http get method
   * @param {string} endpoint
   */
  async getData(endpoint) {
    const complUrl = this.normalizeEndpoint(endpoint);
    return axios.get(complUrl, config);
  }

  /**
   * Standard http post method
   * @param {string} endpoint
   * @param {any} body
   */
  async postData(endpoint, body) {
    const complUrl = this.normalizeEndpoint(endpoint);
    return axios.post(complUrl, body, config);
  }

  /**
   * Standard http put method
   * @param {string} endpoint
   * @param {any} body
   */
  async putData(endpoint, body) {
    const complUrl = this.normalizeEndpoint(endpoint);
    return axios.put(complUrl, body, config);
  }

  normalizeEndpoint(endpoint) {
    if (!endpoint) {
      throw new Error(errors.faultyUrlOrEndpoint);
    }
    return endpoint.indexOf('/') === 0
      ? endpoint
      : `/${endpoint}`;
  }
}

export default new ApiService();
