import moment from 'moment';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHospital, faHouse, faMars, faQuestion, faTransgender, faVenus,
} from '@fortawesome/free-solid-svg-icons';


const timezoneOffset = (new Date().getTimezoneOffset() * (-1)) / 60;
export const noDataAvailableDate = new Date(1970, 0, 1);

const helpers = {

  formatISODate(isoString) {
    return moment(isoString).utcOffset(timezoneOffset, false).format('DD.MM.YYYY, HH:mm:ss');
  },

  getTimestampWithTimezone(isoString) {
    return moment(isoString).utcOffset(timezoneOffset, false).valueOf();
  },

  getMappedImmunsuppressivaString(value, t) {
    switch (value) {
    case 0:
      return t('medication_adherence.skipped');
    case 1:
      return t('medication_adherence.not');
    case 2:
      return t('medication_adherence.done');
    default:
      return 'error';
    }
  },

  getValueConfigForTreatment(valueConfig, treatment) {
    return (treatment === 'CAR_T' ? valueConfig.symptoms.carT : valueConfig.symptoms.alloTx);
  },

  getMappedOtherMedicationString(value, t) {
    switch (value) {
    case 0:
      return t('medication_adherence.skipped');
    case 1:
      return t('medication_adherence.not');
    case 2:
      return t('medication_adherence.partly');
    case 3:
      return t('medication_adherence.done');
    default:
      return 'error';
    }
  },

  /**
   * If the number is a single digit, it is returned preceded by a zero
   * @param number {number}
   * @returns {string}
   */
  toDoubleDigit(number) {
    if (number < 10) {
      return `0${number}`;
    }
    return `${number}`;
  },

  getDateOnlyString(date, showYear = false) {
    const dayString = this.toDoubleDigit(date.getDate());
    const monthString = this.toDoubleDigit(date.getMonth() + 1);
    const yearString = date.getFullYear().toString();
    return `${dayString}.${monthString}${showYear ? '.'.concat(yearString) : ''}`;
  },

  /**
   * Returns the date as a well readable string. If the date is today or yesterday.
   * Will be returned as a string instead of the date today/yesterday. E.g. 'Yesterday, 11:22'
   * showTime: set to false to only get the date, e.g. 'Yesterday'
   * showYear: set to true to get the year of the date appended, e.g. '01.01.2024, 11:22'
   * @param date {Date}
   * @param t
   * @param showTime {boolean}
   * @param showYear {boolean}
   * @returns {string}
   */
  getDateString(date, t, showTime = true, showYear = false) {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    // set dateString to either today, yesterday or the formatted date
    let dateString;
    if (today.toDateString() === date.toDateString()) {
      dateString = t('helper.today');
    } else if (yesterday.toDateString() === date.toDateString()) {
      dateString = t('helper.yesterday');
    } else {
      dateString = this.getDateOnlyString(date, showYear);
    }

    const timeString = `${this.toDoubleDigit(date.getHours())}:${this.toDoubleDigit(date.getMinutes())}`;
    return `${dateString}${showTime ? `, ${timeString}` : ''}`;
  },

  getSexIcon(sex) {
    if (sex === 'female') {
      return (<FontAwesomeIcon icon={faVenus} size="1x"/>);
    } else if (sex === 'male') {
      return (<FontAwesomeIcon icon={faMars} size="1x"/>);
    } else if (sex === 'X') {
      return (<FontAwesomeIcon icon={faTransgender} size="1x"/>);
    } else {
      return (<FontAwesomeIcon icon={faQuestion} size="1x"/>);
    }
  },

  getHomeIcon(mode) {
    if (mode === 'HOME') {
      return (<FontAwesomeIcon size="1x" icon={faHouse}/>);
    } else {
      return (<FontAwesomeIcon size="1x" icon={faHospital}/>);
    }
  },

  getAge(birthday) {
    // calculates the age of the patient
    let age;
    if (birthday) {
      const currentDate = new Date();
      age = currentDate.getFullYear() - birthday.substring(6, 10);
      const month = currentDate.getMonth() + 1 - birthday.substring(3, 5);
      if (month < 0 || (month === 0 && currentDate.getDate() < birthday.substring(0, 2))) {
        age--;
      }
    }
    return age;
  },

  /**
   * Calculates number of weeks between to dates.
   * @param fromDate
   * @param toDate
   * @returns {number}
   */
  weeksBetween(fromDate, toDate) {
    return Math.round((toDate - fromDate) / (7 * 24 * 60 * 60 * 1000));
  },

  /**
   * Calculate the date from weeksAgo weeks ago
   * @param weeksAgo
   * @returns {Date}
   */
  calculateDateWeeksAgo(weeksAgo) {
    return new Date(new Date().getTime() - (weeksAgo * 7 * 24 * 60 * 60 * 1000));
  },

  getLatestDateToday() {
    const dateToday = new Date();
    dateToday.setHours(23, 59, 59, 999);
    return dateToday.getTime();
  },

  createExportableCanvas(ogCanvasElementId) {
    const ogCanvas = document.getElementById(ogCanvasElementId);
    // copy canvas because we do not want to change the original canvas
    const newCanvas = document.createElement('canvas');
    const ctx = newCanvas.getContext('2d');
    newCanvas.width = ogCanvas.width;
    newCanvas.height = ogCanvas.height;
    ctx.drawImage(ogCanvas, 0, 0);
    // add background for better visibiltiy
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);
    return newCanvas;
  },

};


export default helpers;

