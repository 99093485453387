import React, { useEffect, useState } from 'react';
import * as Scroll from 'react-scroll';
import { useDispatch, useSelector } from 'react-redux';
import TitleService from '../../services/title/titleService';
import './Dashboard.scss';
import './Dashboard.scoped.scss';
import PatientsGrid from './patients-grid/PatientsGrid';
import PatientFilterService from '../../services/patientFilterService/patientFilterService';
import NewDataService from '../../services/newDataService/newDataService';
import scrollStateService from '../../services/scrollStateService/scrollStateService';
import { fetchPatients, selectAllPatients, selectLatestEntryDateFromPatients } from '../../redux/patientsSlice';
import { updateNewPatientsData } from '../../redux/newPatientDataSlice';
import { noDataAvailableDate } from '../../helpers/staticHelperMethods';

/**
 * This component is one of the main-pages, containing every patient through patient-grid
 * Services involved are TitleService triggering Title in Header,
 * PatientFilterService filtering through surname/name including FilterBar as Component.
 * Also displaying Notifications e.g Loading (for delays) or Errors
 * @hideconstructor
 * @returns {function} ReactElement represents
 * Filterbar and PatientsGrid
 * @see FilterBar
 * @see PatientsGrid
 * @see TitleService
 * @see PatientFilterService
 * @see withLoadingNotification
 */
function Dashboard() {

  const dispatch = useDispatch();

  const latestEntryDate = useSelector(selectLatestEntryDateFromPatients);
  const patients = useSelector(selectAllPatients);
  const patientsStatus = useSelector(state => state.patients.status);
  const valueConfig = useSelector(state => state.valueConfig.valueConfig);
  const scroll = Scroll.animateScroll;

  const [currentPatients, setCurrentPatients] = useState(patients);
  const [filterString, setFilterString] = useState('');
  const [filtered, setFiltered] = useState(false);

  /**
   * If a sorting was saved in the session memory,
   * load it so that the sorting is not reset completely
   * @returns {any|{type: string, ascending: boolean}}
   */
  const getSorting = () => {
    const sortedString = sessionStorage.getItem('sorted');
    if (sortedString) {
      return JSON.parse(sortedString);
    }
    return { type: 'newData', ascending: true };
  };
  const [sorted, setSorted] = useState(getSorting());

  const getTreatmentFilter = () => {
    const treatmentFilter = sessionStorage.getItem('treatmentFilter');
    if (treatmentFilter) {
      return JSON.parse(treatmentFilter);
    }
    return { treatment: 'allTreatments' };
  };
  const [treatmentFilter, setTreatmentFilter] = useState(getTreatmentFilter());

  const handleFilterTextChange = (filterEvent) => {
    // filterEvent.preventDefault();
    const inputValue = filterEvent.target.value;
    setFilterString(inputValue);

    const emptyFilter = (inputValue.length === 0);
    if (emptyFilter) {
      setFiltered(false);
      return;
    }
    setFiltered(true);
  };

  const handleSort = (patientsToSort) => {
    if (!patientsToSort) {
      return patientsToSort;
    }
    const patientToSortTmp = [...patientsToSort];
    switch (sorted.type) {
    case 'newData':
      patientToSortTmp.sort((a, b) => (
        new Date(b.latestEntry) ?? noDataAvailableDate) -
          new Date(a.latestEntry) ?? noDataAvailableDate);
      break;
    case 'transplantDate':
      patientToSortTmp.sort((a, b) => new Date(b.transplant_date) - new Date(a.transplant_date));
      break;
    case 'name':
      patientToSortTmp.sort(((a, b) => {
        if (a.surname && b.surname) {
          const nameA = a.surname.toUpperCase();
          const nameB = b.surname.toUpperCase();
          if (nameA === nameB) return 0;
          else {
            return (nameA < nameB) ? -1 : 1;
          }
        } else {
          return 0;
        }
      }));
      break;
    default:
      break;
    }
    if (patientToSortTmp && !sorted.ascending) patientToSortTmp.reverse();
    return patientToSortTmp;
  };

  const handleTreatmentFilter = (patientsToFilter) => {
    if (!patientsToFilter) {
      return patientsToFilter;
    }
    switch (treatmentFilter.treatment) {
    case 'alloTx':
      return patientsToFilter.filter(p => p.treatment === 'ALLO_TX');
    case 'carT':
      return patientsToFilter.filter(p => p.treatment === 'CAR_T');
    case 'allTreatments':
    default:
      return patientsToFilter;
    }

  };

  /**
   * Save the sorting additionally in the session storage so
   * that it is not discarded when another view is invoked
   * @param sortedToSave
   */
  const saveSorting = (sortedToSave) => {
    sessionStorage.setItem('sorted', JSON.stringify(sortedToSave));
    setSorted(sortedToSave);
  };

  const saveTreatmentFilter = (treatmentFilterToSave) => {
    sessionStorage.setItem('treatmentFilter', JSON.stringify(treatmentFilterToSave));
    setTreatmentFilter(treatmentFilterToSave);
  };

  const handleTreatmentFilterSelection = (selectedFilter) => {
    saveTreatmentFilter({ treatment: selectedFilter });
  };

  const handleSortSelection = (selectedSort) => {
    saveSorting({ type: selectedSort, ascending: sorted.ascending });
  };

  const handleOrderButtonClick = () => {
    saveSorting({ type: sorted.type, ascending: !sorted.ascending });
  };

  const onNewDataButtonClick = () => {
    dispatch(fetchPatients());
    dispatch(updateNewPatientsData({ newPatientData: false }));
  };

  const emitDashboardEvent = () => {
    const dashboardPageEvent = new CustomEvent('dashboardPageEvent', {
      detail: {
        sorted,
        treatmentFilter,
        handleOrderButtonClick,
        handleSortSelection,
        handleFilterTextChange,
        onNewDataButtonClick,
        handleTreatmentFilterSelection,
      },
    });
    document.dispatchEvent(dashboardPageEvent);
  };

  const handleScrollPosition = () => {
    const scrollPosition = scrollStateService.getScrollState();
    const options = { smooth: false, duration: 0, delay: 10 };
    if (scrollPosition === 0) {
      scroll.scrollToTop(options);
    } else {
      scroll.scrollTo(scrollPosition, options);
    }
  };

  /**
   * Apply filter and sort on patients array
   */
  const getCurrentPatients = () => {
    let filteredAndSorted = patients;
    if (filtered) {
      filteredAndSorted = PatientFilterService.filterPatients(patients, filterString);
    }
    if (treatmentFilter) {
      filteredAndSorted = handleTreatmentFilter(filteredAndSorted);
    }
    if (sorted) {
      filteredAndSorted = handleSort(filteredAndSorted);
    }
    if (filteredAndSorted)
      setCurrentPatients([...filteredAndSorted]);
  };

  /**
   * Function that checks if newer patient data is available.
   * If newer patient data are available, a corresponding toast is opened.
   */
  const checkForNewData = () => {
    NewDataService.newData(latestEntryDate.toISOString())
      .then(newData => {
        if (newData) {
          dispatch(updateNewPatientsData({
            newPatientData: true,
          }));
        }
      }).catch((error) => {
        console.error(error);
      });
  };

  /**
   * Hook for initial component mount
   */
  useEffect(() => {
    TitleService.setTitle('Dashboard');
    handleScrollPosition();
  }, []);

  useEffect(() => {
    getCurrentPatients();

    // set the interval that checks if newer patient data is available
    const interval = setInterval(() => {
      if (patientsStatus === 'succeeded') {
        checkForNewData();
      }
    }, 300000); // 5 minutes
    return () => clearInterval(interval);
  }, [patients]);

  /**
   * Hook for updating component after sort and filter state change
   */
  useEffect(() => {
    if (patients) {
      getCurrentPatients();
      emitDashboardEvent();
    }
  }, [filterString, sorted, treatmentFilter]);

  return (
    <PatientsGrid
      filteredPatients={currentPatients}
      valueLimits={valueConfig}
    />
  );
}

Dashboard.propTypes = {};
Dashboard.defaultProps = {};

export default Dashboard;
