import { Outlet } from 'react-router-dom';
import React from 'react';
import useAuth from '../../hooks/useAuth';
import Login from '../Login/Login';

function AuthGuard() {
  const { isInitialized, isLoggedIn } = useAuth();

  if (!isInitialized) {
    return null;
  }

  if (!isLoggedIn) {
    return <Login />;
  }

  return <Outlet />;
}

export default AuthGuard;
