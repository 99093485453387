/**
 * Provides functions that return configurations for charts.
 */
class ChartConfigService {
  /**
   * Returns the matching config to the passed parameters.
   * @param chartProp
   * @param fromDate
   * @param toDate
   * @param valueConfig
   * @returns {plugins: {legend: {display: boolean}, title: {display: boolean}},
   * responsive: boolean, scales: {x: {min: number, max: number,
   * time: {displayFormats: {day: string}, unit: string, unitStepSize: number,
   * distribution: string, tooltipFormat: string}, type: string},
   * y: {afterDataLimits: scales.y.afterDataLimits,
   * ticks: {display: boolean, stepSize}, position: string, distribution: string},
   * y2: {afterDataLimits: scales.y2.afterDataLimits, ticks: {display: boolean, stepSize},
   * position: string, distribution: string}}, interaction: {mode: string, intersect: boolean},
   * maintainAspectRatio: boolean}
   */
  getChartOptions(chartProp, fromDate, toDate, valueConfig) {
    const chartName = chartProp.name;
    switch (chartName) {
    case 'wellBeing':
      return this.getWellBeingChartConfig(fromDate, toDate);
    case 'temperature':
      return this.getTemperatureChartConfig(fromDate, toDate, valueConfig);
    case 'weight': {
      const values = chartProp.data.map((chartData) => (chartData.value));
      return this.getWeightChartConfig(fromDate, toDate, valueConfig, values);
    }
    case 'pulse':
      return this.getPulseChartConfig(fromDate, toDate, valueConfig);
    case 'blood_pressure':
      return this.getBloodPressureChartConfig(fromDate, toDate);
    case 'symptoms':
      return this.getSymptomChartConfig(fromDate, toDate);
    case 'other_medication':
      return this.getOtherMedicationChartConfig(fromDate, toDate);
    case 'immunsuppressiva':
      return this.getImmunsuppressivaChartConfig(fromDate, toDate);
    case 'iceScore':
      return this.getIceScoreChartConfig(fromDate, toDate);
    default:
      return {};
    }
  }

  /**
   * Returns the start and end date for a chart
   * @param fromDate
   * @param toDate
   * @returns {{minDate: Date, maxDate: Date}}
   */
  calculateMaxAndMinDate(fromDate, toDate) {
    const minDate = new Date(fromDate);
    minDate.setDate(minDate.getDate());
    minDate.setHours(0, 0, 0, 0);

    const maxDate = new Date(toDate);
    maxDate.setDate(maxDate.getDate() + 1);
    maxDate.setHours(0, 0, 0, 0);

    return { minDate, maxDate };
  }

  /**
   * Returns the config for the temperature chart.
   * @param fromDate
   * @param toDate
   * @returns chart.js config for temperature
   */
  getWellBeingChartConfig(fromDate, toDate) {
    const config = this.getDefaultChartConfig(1, 10, 1, fromDate, toDate, false,
      'ddd D.MM.');
    return config;
  }

  /**
   * Returns the config for the temperature chart.
   * @param fromDate
   * @param toDate
   * @param valueConfig
   * @returns chart.js config for temperature
   */
  getTemperatureChartConfig(fromDate, toDate, valueConfig) {
    const yStepSize = 0.5;
    const defaultConfig = this.getDefaultChartConfig(
      valueConfig.valueConfig.parameters.temperature.min,
      valueConfig.valueConfig.parameters.temperature.max, yStepSize, fromDate, toDate,
    );
    return {
      ...defaultConfig,
      backgroundRules: [{
        yAxisLowerLimit: 35.5,
      },
      {
        yAxisUpperLimit: 37.5,
      },
      {
        scale: yStepSize,
      },
      {
        fillColor: '#eeeeee',
      },
      ],
    };
  }

  getPulseChartConfig(fromDate, toDate, valueConfig) {
    const yStepSize = 10;
    const defaultConfig = this.getDefaultChartConfig(valueConfig.valueConfig.parameters.pulse.min,
      valueConfig.valueConfig.parameters.pulse.max, yStepSize, fromDate, toDate);
    return {
      ...defaultConfig,
      backgroundRules: [{
        yAxisLowerLimit: valueConfig.valueConfig.parameters.pulse.min,
      },
      {
        yAxisUpperLimit: valueConfig.valueConfig.parameters.pulse.max,
      },
      {
        scale: yStepSize,
      },
      {
        fillColor: '#eeeeee',
      },
      ],
    };
  }

  /**
   * Returns the config for the weight chart.
   * @param fromDate
   * @param toDate
   * @param valueConfig
   * @param values
   * @returns chart.js config for weight
   */
  getWeightChartConfig(fromDate, toDate, valueConfig, values) {
    const getMinMaxValue = () => {
      // if no values are given, set default values for an empty chart
      if (values.length === 0) {
        return { minValue: 60, maxValue: 70 };
      }
      if (values.length === 1) {
        return { minValue: values[0] - 5, maxValue: values[0] + 5 };
      }
      const minValue = Math.min(...values);
      const maxValue = Math.max(...values);
      return { minValue: minValue - 2, maxValue: maxValue + 2 };
    };
    const maxMinValue = getMinMaxValue();
    return this.getDefaultChartConfig(maxMinValue.minValue, maxMinValue.maxValue, 2,
      fromDate, toDate);
  }

  /**
   * Returns the config for the blood pressure chart.
   * @param fromDate
   * @param toDate
   */
  getBloodPressureChartConfig(fromDate, toDate) {
    const yStepSize = 10;
    const defaultConfig = this.getDefaultChartConfig(50, 220, yStepSize, fromDate, toDate, true);
    return {
      ...defaultConfig,
      backgroundRules: [{
        yHypotonieLowerLimit: 60,
      },
      {
        yHypotonieUpperLimit: 90,
      },
      {
        yHypertonieLowerLimit: 85,
      },
      {
        yHypertonieUpperLimit: 140,
      },
      {
        scale: yStepSize,
      },
      {
        fillColorHypotonie: '#eeeeee',
      },
      {
        fillColorHypertonie: '#d3d3d3',
      },
      {
        filColorOverlapping: '#b3b3b3',
      },
      ],
    };
  }

  /**
   * Returns the config for the symptom chart.
   * @param fromDate
   * @param toDate
   */
  getSymptomChartConfig(fromDate, toDate) {
    return this.getDefaultChartConfig(0, 20, 1, fromDate, toDate,
      true, 'ddd D.MM.');
  }

  /**
   * Returns the config for the other medication chart.
   * @param fromDate
   * @param toDate
   */
  getOtherMedicationChartConfig(fromDate, toDate) {
    const customYTickConfig = {
      callback(val) {
        switch (val) {
        case 3:
          // eslint-disable-next-line react/react-in-jsx-scope
          return '✔️';
        case 2:
          return '🟠';
        case 1:
          return '❌';
        case 0:
          return 'k. A.';
        default:
          return '';
        }
      },
    };
    return this.getDefaultChartConfig(0, 3.5, 1,
      fromDate, toDate, false, 'ddd D.MM.', 5, customYTickConfig);
  }

  /**
   * Returns the config for the other immunsuppressiva chart.
   * @param fromDate
   * @param toDate
   */
  getImmunsuppressivaChartConfig(fromDate, toDate) {
    const customYTickConfig = {
      callback(val) {
        switch (val) {
        case 2:
          return '✔️';
        case 1:
          return '❌';
        case 0:
          return 'k. A.';
        default:
          return '';
        }
      },
    };
    return this.getDefaultChartConfig(0, 2.5, 1,
      fromDate, toDate, false, 'ddd D.MM.', 5, customYTickConfig);
  }

  /**
   * Returns a default configuration with your passed parameters.
   * @param yMin
   * @param yMax
   * @param yStepSize
   * @param fromDate
   * @param toDate
   * @param showLegend
   * @param toolTipDateFormat
   * @param pointRadius
   * @param yTicksConfig
   * @returns {{plugins: {legend: {display: boolean}, title: {display: boolean}},
   * responsive: boolean, scales: {x: {min: number, max: number,
   * time: {displayFormats: {day: string}, unit: string, unitStepSize: number,
   * distribution: string, tooltipFormat: string}, type: string},
   * y: {afterDataLimits: scales.y.afterDataLimits, ticks: {display: boolean, stepSize},
   * position: string, distribution: string},
   * y2: {afterDataLimits: scales.y2.afterDataLimits,
   * ticks: {display: boolean, stepSize}, position: string, distribution: string}},
   * interaction: {mode: string, intersect: boolean}, maintainAspectRatio: boolean}}
   */
  getDefaultChartConfig(yMin, yMax, yStepSize, fromDate, toDate, showLegend = false,
    toolTipDateFormat = 'ddd D.MM. HH:mm', pointRadius = 3, yTicksConfig = undefined) {
    const { minDate, maxDate } = this.calculateMaxAndMinDate(fromDate, toDate);
    // use default config if no yTicksConfig given
    const yTicks = yTicksConfig || { display: true, stepSize: yStepSize };
    return {
      responsive: true,
      maintainAspectRatio: true,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      plugins: {
        title: {
          display: false,
        },
        legend: {
          display: showLegend,
        },
      },
      elements: {
        point: {
          radius: pointRadius,
        },
      },
      scales: {
        y: {
          position: 'left',
          distribution: 'linear',
          ticks: {
            ...yTicks,
          },
          afterDataLimits: (scale) => {
            scale.max = yMax;
            scale.min = yMin;
          },
        },
        y2: {
          position: 'right',
          distribution: 'linear',
          ticks: {
            ...yTicks,
          },
          afterDataLimits: (scale) => {
            scale.max = yMax;
            scale.min = yMin;
          },
        },
        x: {
          type: 'time',
          min: minDate.getTime(),
          max: maxDate.getTime(),
          time: {
            distribution: 'linear',
            unit: 'day',
            unitStepSize: 1,
            displayFormats: {
              day: 'D.MM',
            },
            tooltipFormat: toolTipDateFormat,
          },
        },
      },
    };
  }

  /**
   * Returns the appropriate chart.js plugin for the given chart name.
   * @param chartName
   * @returns chart.js plugin
   */
  getChartPlugins(chartName) {
    if (chartName === 'temperature') {
      return [{
        beforeDraw(chart) {
          // get options
          const rules = chart.options.backgroundRules;
          const { yAxisLowerLimit } = rules[0];
          const { yAxisUpperLimit } = rules[1];
          const { scale } = rules[2];
          const { fillColor } = rules[3];
          // get chart data
          const { ctx } = chart;
          const xaxis = chart.scales.x;
          const yaxis = chart.scales.y;
          // calculate needed values
          const distance = yAxisUpperLimit - yAxisLowerLimit;
          const pixelPerTick = yaxis.height / (yaxis.ticks.length - 1);
          const yaxisStart = ((yAxisUpperLimit - yaxis.min) * pixelPerTick) / scale;
          // draw rectangle
          ctx.fillStyle = fillColor;
          ctx.fillRect(xaxis.left, yaxis.bottom - yaxisStart,
            xaxis.width, (distance * pixelPerTick) / scale);
        },
      }];
    }
    if (chartName === 'blood_pressure') {
      return [{
        beforeDraw(chart) {
          // get options
          const rules = chart.options.backgroundRules;
          const { yHypotonieLowerLimit } = rules[0];
          const { yHypotonieUpperLimit } = rules[1];
          const { yHypertonieLowerLimit } = rules[2];
          const { yHypertonieUpperLimit } = rules[3];
          const { scale } = rules[4];
          const { fillColorHypotonie } = rules[5];
          const { fillColorHypertonie } = rules[6];
          const { filColorOverlapping } = rules[7];
          // calculate overlapping
          const overlappingUpperLimit = yHypotonieUpperLimit;
          const overlappingLowerLimit = yHypertonieLowerLimit;
          // get chart data
          const { ctx } = chart;
          const xaxis = chart.scales.x;
          const yaxis = chart.scales.y;
          // calculate needed values
          const pixelPerTick = yaxis.height / (yaxis.ticks.length - 1);
          const hypoDistance = yHypotonieUpperLimit - yHypotonieLowerLimit;
          const hyperDistance = yHypertonieUpperLimit - yHypertonieLowerLimit;
          const overlappingDistance = overlappingUpperLimit - overlappingLowerLimit;
          const yaxisHypoStart = ((yHypotonieUpperLimit - yaxis.min) * pixelPerTick) / scale;
          const yaxisHyperStart = ((yHypertonieUpperLimit - yaxis.min) * pixelPerTick) / scale;
          const yaxisOverlappingStart =
            ((overlappingUpperLimit - yaxis.min) * pixelPerTick) / scale;
          // draw rectangle
          ctx.fillStyle = fillColorHypotonie;
          ctx.fillRect(xaxis.left, yaxis.bottom - yaxisHypoStart,
            xaxis.width, (hypoDistance * pixelPerTick) / scale);
          ctx.fillStyle = fillColorHypertonie;
          ctx.fillRect(xaxis.left, yaxis.bottom - yaxisHyperStart,
            xaxis.width, (hyperDistance * pixelPerTick) / scale);
          ctx.fillStyle = filColorOverlapping;
          ctx.fillRect(xaxis.left, yaxis.bottom - yaxisOverlappingStart,
            xaxis.width, (overlappingDistance * pixelPerTick) / scale);
        },
      }];
    }
    return [];
  }

  /**
   *
   * @param fromDate
   * @param toDate
   * @returns {undefined}
   */
  getIceScoreChartConfig(fromDate, toDate) {
    const yStepSize = 1;
    const minIceScore = 0;
    const maxIceScore = 10;
    const defaultConfig = this.getDefaultChartConfig(minIceScore,
      maxIceScore, yStepSize, fromDate, toDate);
    return {
      ...defaultConfig,
      backgroundRules: [{
        yAxisLowerLimit: minIceScore,
      },
      {
        yAxisUpperLimit: maxIceScore,
      },
      {
        scale: yStepSize,
      },
      {
        fillColor: '#eeeeee',
      },
      ],
    };
  }
}

export default new ChartConfigService();
