import React from 'react';
import './InviteHeader.scss';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import HeaderContainer from '../../wrapper/header/HeaderContainer';

/**
 * This component is always displayed it communicates with
 * TitleService to show a Title corresponding to page content.
 * Right-handed is the logged-in user and a logout button
 * @hideconstructor
 * @returns {function} ReactElement represents
 * Header of the page
 * @see TitleService
 */
function InviteHeader(props) {
  const { t } = props;

  return (
    <HeaderContainer>
      <span className="inviteDetail"> {t('inviteHeader.invite')}</span>
    </HeaderContainer>
  );
}

InviteHeader.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(InviteHeader);


