import useFeatureFlags from './useFeatureFlags';
import helpers from '../../helpers/staticHelperMethods';

export default function useDisplayedSymptoms(allSymptoms, valueConfig, treatment) {
  const validSymptomsForTreatment = helpers.getValueConfigForTreatment(valueConfig, treatment)
    .map(x => x.symptomName);
  const { displayAllSymptoms } = useFeatureFlags();
  return displayAllSymptoms
    ? allSymptoms.filter(symptom => validSymptomsForTreatment.indexOf(symptom.name) > -1)
    : allSymptoms.filter(symptom => symptom.values.some(s => s.value && s.value !== 0));
}
