import { configureStore } from '@reduxjs/toolkit';

import patientsReducer from './patientsSlice';
import patientsHeaderReducer from './patientHeaderSlice';
import valueConfigReducer from './valueConfigSlice';
import newPatientDataReducer from './newPatientDataSlice';

export default configureStore({
  reducer: {
    patients: patientsReducer,
    patientHeader: patientsHeaderReducer,
    valueConfig: valueConfigReducer,
    newPatientData: newPatientDataReducer,
  },
});
