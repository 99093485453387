import React, { useEffect, useRef, useState } from 'react';
import QRCodeStyling from 'qr-code-styling';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import TitleService from '../../services/title/titleService';
import './InviteComponent.scss';
import logo from '../../assets/logo.svg';
import PatientInformationForm from '../edit/PatientInformationForm';
import { upsertPatientByStudyNumber } from '../../redux/patientsSlice';
import ApiService from '../../services/apiService';
import RegistrationSuccess from './RegistrationSuccess';
import { updateNewPatientsData } from '../../redux/newPatientDataSlice';
import useAvailableTreatments from '../feature-flags/useAvailableTreatments';

// an interactive tool for generating different styles can be found at https://qr-code-styling.com
const qrCode = new QRCodeStyling(
  {
    width: 300,
    height: 300,
    image: logo,
    dotsOptions: {
      color: '#000000',
      type: 'dots',
    },
    imageOptions: {
      margin: 10,
    },
    cornersSquareOptions: {
      type: '',
      color: '#000000',
    },
    cornersDotOptions: {
      type: 'square',
      color: '#000000',
    },
  },
);

const InviteComponent = ({ t }) => {
  const dispatch = useDispatch();

  const qrRef = useRef(null);
  const currentPatient = useRef({});

  const [appUrl, setAppUrl] = useState('');
  const [editFeedback, setEditFeedback] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [registrationKey, setRegistrationKey] = useState();
  const [patientAlreadyExists, setPatientAlreadyExists] = useState(false);

  useEffect(() => {
    TitleService.setTitle('Invitation');
    ApiService.getData(process.env.REACT_APP_APP_HOSTNAME_ENDPOINT).then(result => {
      const url = result.data.host;
      qrCode.update({ data: `http://${url}/` });
      setAppUrl(url);
    });
  }, []);

  useEffect(() => {
    qrCode.append(qrRef.current);
  }, [qrRef.current]);

  // eslint-disable-next-line consistent-return
  const onSubmit = async (event) => {
    event.preventDefault();

    // save changes
    try {
      setSubmitting(true);

      // update patient data in redux store
      dispatch(upsertPatientByStudyNumber({
        study_number: currentPatient.study_number,
        editedPatient: currentPatient,
      }));

      // post changes to backend
      const body = {
        study_number: currentPatient.study_number,
        name: currentPatient.name,
        surname: currentPatient.surname,
        sex: currentPatient.sex,
        birthday: currentPatient.birthday,
        phone: currentPatient.phone,
        city: currentPatient.city,
        street: currentPatient.street,
        postcode: currentPatient.postcode,
        treatment: currentPatient.treatment,
      };
      const endpoint = process.env.REACT_APP_ALL_PATIENTS_ENDPOINT;
      const responseData = (await ApiService.putData(endpoint, body)).data;
      setRegistrationKey(responseData.registrationKey);
      dispatch(updateNewPatientsData({ newPatientData: true }));
    } catch (err) {
      if (err.response.status === 406) {
        setPatientAlreadyExists(true);
      }
      setEditFeedback(t('edit.change_failure'));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="registration-container">
      {
        registrationKey
          ? (
            <RegistrationSuccess
              registrationKey={registrationKey}
              studyNumber={currentPatient.study_number}
            />
          ) : (
            <PatientInformationForm
              editFeedback={editFeedback}
              setEditFeedback={setEditFeedback}
              currentPatient={currentPatient}
              onSubmit={onSubmit}
              submitLabel={t('create.confirm')}
              submitting={submitting}
              patientAlreadyExists={patientAlreadyExists}
              resetPatientAlreadyExists={() => setPatientAlreadyExists(false)}
              availableTreatments={useAvailableTreatments()}
            />
          )
      }
      <div className="qrcode-container">
        <div ref={qrRef}/>
        <div className="app-url">{t('edit.opens_url', { url: appUrl })}</div>
      </div>
    </div>
  );
};

InviteComponent.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(InviteComponent);
