import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { withTranslation } from 'react-i18next';
import helpers from '../../../helpers/staticHelperMethods';
import './ValueToolTip.scss';


const ValueToolTip = (props) => {

  const {
    tooltipId,
    name,
    timestamp,
    formatDate,
    t,
    showYearInTimestamp,
    showTimeInTimestamp,
    showPlaceholder,
  } = props;

  const noDataPlaceHolder = t('title.no_data_placeholder');
  const dateString = formatDate ?
    helpers.getDateString(new Date(timestamp), t, showTimeInTimestamp, showYearInTimestamp)
    : timestamp;

  return (
    <ReactTooltip id={tooltipId} type="dark" effect="float" place="bottom">
      <div className="value-tool-tip-date">
        {showPlaceholder ? noDataPlaceHolder : dateString}
      </div>
      <div className="value-tool-tip-name">
        {name}
      </div>
    </ReactTooltip>
  );
};

ValueToolTip.propTypes = {
  tooltipId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  timestamp: PropTypes.string.isRequired,
  formatDate: PropTypes.bool,
  t: PropTypes.func.isRequired,
  showYearInTimestamp: PropTypes.bool,
  showTimeInTimestamp: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
};

ValueToolTip.defaultProps = {
  formatDate: true,
  showYearInTimestamp: false,
  showTimeInTimestamp: true,
  showPlaceholder: false,
};

export default withTranslation('common')(ValueToolTip);
