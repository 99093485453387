import React from 'react';
import PropTypes from 'prop-types';
import SymptomCard from '../symptom-card/SymptomCard';
import SymptomShape from '../../../../../../prop-types/symptom';
import './SymptomGrid.scss';
import useDisplayedSymptoms from '../../../../../feature-flags/useDisplayedSymptoms';
import treatmentShape from '../../../../../../prop-types/treatment';
import helpers from '../../../../../../helpers/staticHelperMethods';


/**
 * A flex grid containing all symptom indicators
 * @param props
 */
function SymptomGrid(props) {
  const { symptoms, valueLimits, treatment } = props;
  const displayedSymptoms = useDisplayedSymptoms(
    symptoms, valueLimits, treatment,
  );
  return (
    <div className="row flex-nowrap">
      {
        displayedSymptoms
          .map(symptom => (
            <SymptomCard
              key={symptom.name}
              symptom={symptom}
              valueLimits={helpers.getValueConfigForTreatment(valueLimits, treatment)
                .filter(
                  s => s.symptomName === symptom.name.toUpperCase(),
                )[0]
              }
            />))
      }
    </div>
  );
}

export default SymptomGrid;

SymptomGrid.propTypes = {
  symptoms: PropTypes.arrayOf(SymptomShape).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  valueLimits: PropTypes.any.isRequired,
  treatment: treatmentShape.isRequired,
};
