import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyringe } from '@fortawesome/free-solid-svg-icons';
import Treatment from '../../../prop-types/treatment';
import './TreatmentInfo.scss';

const TreatmentInfo = (props) => {

  const {
    treatment, t,
  } = props;

  const treatmentString = treatment === 'CAR_T' ? t('title.car_t_short') : t('title.allo_tx_short');

  return (
    <>
      <div className="fa-layers fa-fw syringeIcon syringe-icon-margin">
        <FontAwesomeIcon icon={faSyringe}/>
      </div>
      <span className="treatment-text-style on-hover-blue">{t('title.treatment_short')}</span>
      <span className="treatment-value-style on-hover-blue">
        &nbsp;{treatmentString}
      </span>
    </>

  );
};

export default withTranslation('common')(TreatmentInfo);

TreatmentInfo.propTypes = {
  treatment: Treatment.isRequired,
  t: PropTypes.func.isRequired,
};
