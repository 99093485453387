import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';
import EvaluationCheckboxes from '../evaluation-checkboxes/EvaluationCheckboxes';

const ConcentrationEvaluation = (props) => {
  const { t } = props;

  return (
    <div className="card col-12 corner-radius">
      <div className="card-body">
        <p>{t('questionnaire.concentration.title')}</p>
        <Table striped hover>
          <thead>
            <tr>
              <th>
                {t('questionnaire.concentration.tableHeaders.answerPatient')}
              </th>
              <th>
                {t('questionnaire.concentration.tableHeaders.evaluation')}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{props.data.numbers?.join(' → ') ?? t('questionnaire.wasSkippedPlaceholder')}</td>
              <td>
                <EvaluationCheckboxes
                  groupName="concentration"
                  accepted={props.data.accepted}
                  setAccepted={props.setOverwrite}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default withTranslation('common')(ConcentrationEvaluation);

ConcentrationEvaluation.propTypes = {
  t: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  setOverwrite: PropTypes.func.isRequired,
};
