import React from 'react';
import { faExclamation, faMessage, faUserGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import scrollStateService from '../../../../../../services/scrollStateService/scrollStateService';
import DayCalculator from '../../../../../common/DayCalculator';
import patientShape from '../../../../../../prop-types/patient';
import MedicationAdherence from '../../../../../common/medication-adherence/MedicationAdherence';
import Age from './age/Age';
import PatientName from './patient-name/PatientName';
import BloodPressure from './blood-pressure/BloodPressure';
import './PatientCardHeader.scss';
import helpers from '../../../../../../helpers/staticHelperMethods';
import { calculateQuestionnaireScore } from '../../../../../../helpers/calculateQuestionnaireScore';
import TreatmentInfo from '../../../../../common/treatment-info/TreatmentInfo';
import IceScore from '../../../../../common/ice-score/IceScore';
import ValueDisplay from './value-display/ValueDisplay';
import e2eTestIds from '../../../../../e2eTestIds';
import WellBeing from './well-being/WellBeing';
import useFeatureFlags from '../../../../../feature-flags/useFeatureFlags';

function PatientCardHeader(props) {
  const { patient, t } = props;

  const immunosuppressive =
    patient.immunsuppressiva.length !== 0
      ? patient.immunsuppressiva[0]
      : { value: -1, timestamp: new Date() };

  const otherMedication =
    patient.other_medication && patient.other_medication.length !== 0
      ? patient.other_medication[0]
      : { value: -1, timestamp: new Date() };

  const passWellBeing =
    patient.wellBeing && patient.wellBeing.length !== 0
      ? patient.wellBeing[0]
      : null;

  const isPatientCART = patient.treatment === 'CAR_T';

  const passQuestionnaireScore = patient.questionnaires.length === 0 ? null : {
    value: calculateQuestionnaireScore(patient, patient.questionnaires[0].date),
    timestamp: patient.questionnaires[0].date,
    updatedAt: patient.questionnaires[0].date,
  };

  const homeIcon = helpers.getHomeIcon(patient.mode);

  const { patientCommentsActive } = useFeatureFlags();
  const isMonitored = !!patient.monitored;

  return (
    <div className="row">
      <div className="col-xl">
        <div className="row center-row-content">
          <span className="patient-card-header-spacing">
            <PatientName
              patientName={patient.name}
              patientSurname={patient.surname}
              patientId={patient.id}
              patientSex={patient.sex}
            />
          </span>
          <span
            className={patient.birthday ? 'patient-card-header-spacing' : ''}
          >
            <Age birthday={patient.birthday}/>
          </span>
          <span className="patient-card-header-spacing">
            <TreatmentInfo treatment={patient.treatment}/>
          </span>
          <span className="patient-card-header-spacing unit-style on-hover-blue">
            <DayCalculator
              dayOfTransplantation={patient.transplant_date}
              tooltipText={patient.treatment === 'CAR_T' ? t('title.car_t_therapy') : t('title.transplantation')}
              tooltipFlag
            />
          </span>
          <span className="patient-card-header-spacing">{homeIcon}</span>
          { isMonitored &&
            <>
              <span className="patient-card-header-spacing">
                <MedicationAdherence
                  immunsuppressivaFlag={patient.immunsuppressiva_flag}
                  immunsuppressiva={immunosuppressive}
                  otherMedication={otherMedication}
                  treatmentMethod={patient.treatment}
                />
              </span>
              <span className="patient-card-header-spacing">
                <ValueDisplay
                  valueArr={patient.temperature}
                  unit="°C"
                  tooltipTitle={t('title.temperature')}
                  data-testid={e2eTestIds.dashboard.patientCard.temperature}
                />
              </span>
              <span className="patient-card-header-spacing">
                <ValueDisplay
                  valueArr={patient.weight}
                  unit="kg"
                  tooltipTitle={t('title.weight')}
                  data-testid={e2eTestIds.dashboard.patientCard.weight}
                />
              </span>
              <span className="patient-card-header-spacing">
                <BloodPressure valueArr={patient.blood_pressure} unit="mmHG"/>
              </span>
              <span className="patient-card-header-spacing">
                <ValueDisplay
                  valueArr={patient.pulse}
                  unit="/min"
                  tooltipTitle={t('title.pulse')}
                  data-testid={e2eTestIds.dashboard.patientCard.pulse}
                />
              </span>
              <span className="patient-card-header-spacing">
                <WellBeing valueAndTimestamp={passWellBeing}/>
              </span>
              { isPatientCART &&
                <span className="patient-card-header-spacing">
                  <IceScore valueAndTimestamp={passQuestionnaireScore}/>
                </span>
              }
            </>
          }
          {patientCommentsActive && isMonitored &&
            <span className="patient-card-header-spacing">
              <Link to={`/patient/${patient.id}/comments`}>
                <Button
                  className="btn-light center-row-content edit-button-margin"
                  onClick={() => scrollStateService.saveScrollState()}
                >
                  <FontAwesomeIcon size="lg" icon={faMessage} color={!patient.isCommentsChecked ? 'orange' : ''}/>
                  {!patient.isCommentsChecked ?? <FontAwesomeIcon size="lg" icon={faExclamation} color="orange"/>}
                </Button>
              </Link>
            </span>
          }
          <span className="patient-card-header-spacing">
            <Link to={`/edit/${patient.study_number}`}>
              <Button
                className="btn-light center-row-content edit-button-margin"
                onClick={() => scrollStateService.saveScrollState()}
                data-testid={e2eTestIds.dashboard.patientCard.edit}
              >
                <FontAwesomeIcon size="xl" icon={faUserGear}/>
              </Button>
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
}

export default withTranslation('common')(PatientCardHeader);

PatientCardHeader.propTypes = {
  t: PropTypes.func.isRequired,
  patient: patientShape.isRequired,
};

