import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import {
  faMars, faQuestion, faTransgender, faVenus,
} from '@fortawesome/free-solid-svg-icons';
import scrollStateService from '../../../../../../../services/scrollStateService/scrollStateService';
import './PatientName.scss';
import e2eTestIds from '../../../../../../e2eTestIds';

/**
 * This component displays the patients' photo, its birthday and sex
 * @hideconstructor
 * @example <PatientName patient={patient}/>
 * @returns {function} ReactElement represents a div containing patients details
 */
const PatientName = (props) => {

  const {
    patientId, patientSex, patientSurname, patientName,
  } = props;

  const pathToPatient = `/patient/${patientId}/details`;
  let sexIcon;
  if (patientSex === 'female') {
    sexIcon = (<FontAwesomeIcon icon={faVenus} />);
  } else if (patientSex === 'male') {
    sexIcon = (<FontAwesomeIcon icon={faMars} />);
  } else if (patientSex === 'X') {
    sexIcon = (<FontAwesomeIcon icon={faTransgender} />);
  } else {
    sexIcon = (<FontAwesomeIcon icon={faQuestion} />);
  }

  const displayName = `${patientSurname}, ${patientName}`;

  return (
    <span>
      <Link
        to={pathToPatient}
        className="link-to-detail"
        onClick={() => {
          scrollStateService.saveScrollState();
        }}
      >
        <span className="summary-content">
          <span className="patient-name" data-testid={e2eTestIds.dashboard.patientCard.patientName}>
            {displayName}
          </span>
          <sup>
            {sexIcon}
          </sup>
        </span>
      </Link>
    </span>
  );
};

export default withTranslation('common')(PatientName);

PatientName.propTypes = {
  patientId: PropTypes.number.isRequired,
  patientName: PropTypes.string,
  patientSurname: PropTypes.string,
  patientSex: PropTypes.string,
};

PatientName.defaultProps = {
  patientName: '?',
  patientSurname: '?',
  patientSex: '',
};
