import React from 'react';
import PropTypes from 'prop-types';
import './Bar.scss';

/**
 * @param {number} props.max Maximal Volume of Bar (mostly 10)
 * @param {number} props.min Minimal Volume of Bar (always 0)
 * @param {number} props.currentValue Value that should be displayed (between min-max)
 * @returns {function}  Bar JSX code that renders div bars
 * @class
 * @classdesc builds a bar with div elements
 * @hideconstructor
 */
const bar = (props) => {
  const {
    max,
    min,
    currentValue,
  } = props;

  let upperBorder = null;
  if (currentValue !== max && currentValue !== min) {
    upperBorder = '0px dashed lightgray';
  }

  const barStyle = {
    height: `${(props.currentValue * 100) / props.max}%`,
    borderTop: upperBorder,
  };

  return (
    <div className="Bar">
      <div
        className="barFill "
        style={barStyle}
      />
    </div>
  );
};

export default bar;

bar.propTypes = {
  max: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  currentValue: PropTypes.number.isRequired,
};
