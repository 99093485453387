/**
 * This service handles the document title
 * @class
 */
class TitleService {

  /**
   * @constructor
   * @property subscribers array of the passed subscriber functions
   * @property title document title
   */
  constructor() {
    this.subscribers = [];
    this.title = document.title;
  }

  /**
   * Saves the passed function to the subscribers array
   * @param {function} subscriber
   */
  subscribe(subscriber) {
    this.subscribers.push(subscriber);
    if (this.title) {
      subscriber(this.title);
    }
  }

  /**
   * Removes the passed function from the subscribers array
   * @param {function} subscriber
   */
  unsubscribe(subscriber) {
    const index = this.subscribers.indexOf(subscriber);
    this.subscribers.splice(index, 1);
  }

  /**
   * Sets the passed title and notifies all subscribers
   * @param {JSX.Element||string} title
   */
  setTitle(title) {
    this.title = title;
    this.subscribers.forEach((subscriber) => {
      subscriber(title);
    });
    document.title = title;
  }
}

export default new TitleService();
