import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import valueAndTimestampShape from '../../../prop-types/valueAndTimestampShape';
import ValueToolTip from '../value-tool-tip/ValueToolTip';
import ScalarValuePieChart from '../scalar-value-pie-chart/ScalarValuePieChart';
import '../../../globalStyles/globalStyles.scss';

const IceScore = (props) => {

  const { valueAndTimestamp, t } = props;

  const tooltipId = uuidv4();

  const label = t('iceScore.iceScore');
  const title = t('title.iceScore');
  const noDataPlaceHolder = t('title.no_data_placeholder');

  let displayElement;

  if (valueAndTimestamp === null) {
    displayElement = noDataPlaceHolder;
  } else {
    displayElement = valueAndTimestamp.value ?? <FontAwesomeIcon icon={faQuestion} color="orange"/>;
  }

  return (
    <div className="scalar-value" data-tip data-for={tooltipId}>
      <span className="scalar-value-label-style on-hover-blue">
        {label}
        &nbsp;
        <span className="scalar-value-value-style">{displayElement}</span>
      </span>
      {
        valueAndTimestamp && valueAndTimestamp.value !== null &&
        <ScalarValuePieChart value={valueAndTimestamp.value} title={title}/>
      }
      <ValueToolTip
        tooltipId={tooltipId}
        name={title}
        timestamp={valueAndTimestamp?.updatedAt}
        showTimeInTimestamp={false}
        showYearInTimestamp
        showPlaceholder={valueAndTimestamp === null}
      />
    </div>
  );
};

export default withTranslation('common')(IceScore);

IceScore.propTypes = {
  valueAndTimestamp: valueAndTimestampShape,
  t: PropTypes.func.isRequired,
};

IceScore.defaultProps = {
  valueAndTimestamp: null,
};
