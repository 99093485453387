import PropTypes from 'prop-types';

const valueAndTimestampShape = PropTypes.shape({
  value: PropTypes.number,
  timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  updatedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
});

export default valueAndTimestampShape;
