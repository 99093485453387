import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Request from '../services/apiService';
import errors from '../errors';
import helpers, { noDataAvailableDate } from '../helpers/staticHelperMethods';
import { calculateQuestionnaireScore } from '../helpers/calculateQuestionnaireScore';
// name of the parameters
const parameterNames = ['wellBeing', 'temperature', 'weight', 'blood_pressure', 'pulse', 'symptoms', 'immunsuppressiva', 'other_medication'];

/**
 * Used to load patient data from the backend.
 */
export const fetchPatients = createAsyncThunk('patients/fetchPatients', async () => {
  const patientsRequest = await Request.getData(process.env.REACT_APP_ALL_PATIENTS_ENDPOINT);
  if (!patientsRequest.data || !patientsRequest.data.patients)
    throw new Error(errors.faultyResponseParameter);
  return patientsRequest.data.patients.map(patient => Object.fromEntries(
    Object.entries(patient).map(([parameterName, parameterValue]) => {
      if (!parameterNames.includes(parameterName)) {
        return [parameterName, parameterValue];
      }

      if (parameterName !== 'symptoms') {
        return [
          parameterName,
          parameterValue.toSorted((a, b) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()),
        ];
      }

      return [
        parameterName,
        parameterValue.map((symptom) => ({
          ...symptom,
          values: symptom.values.toSorted((a, b) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()),
        })),
      ];
    }),
  ));
});

/**
 * Returns all (active) patients from the store.
 * @param state
 */
export const selectAllPatients = (state) => {
  if (state.patients && state.patients.patients) {
    return state.patients.patients.filter((patient) => Boolean(patient.is_active) === true);
  }
  return [];
};

/**
 * Returns the patient for the passed study number.
 * @param state
 * @param studyNumber
 * @returns []
 */
export const selectPatientByStudyNumber = (state, studyNumber) => {
  if (state.patients && state.patients.patients) {
    return state.patients.patients.find(patient =>
      patient.study_number === studyNumber);
  }
  return undefined;
};

/**
 * Returns the patient for the passed patient id.
 * @param state
 * @param patientId
 * @returns {T}
 */
export const selectPatientById = (state, patientId) =>
  state.patients.patients.find(patient => patient.id === patientId);

/**
 * Returns the patient data in a given time period
 * @param state
 * @param patientId
 * @param fromDate
 * @param toDate
 */
export const selectParameterInPeriodFromPatientById = (state, patientId, fromDate, toDate) => {
  const selectedPatient = state.patients.patients.find(patient => patient.id === patientId);

  // If the patient does not exist, return an empty array.
  if (!selectedPatient) {
    return [];
  }
  let parameterNamesForPatient;

  // This should be done through two seperate, const lists, ideally read from app-shared.
  if (selectedPatient.treatment === 'CAR_T') {
    // add ICE score to param after wellbeing
    parameterNamesForPatient = [parameterNames[0], 'iceScore', ...parameterNames.slice(1)];
    // remove immunesuppresiva
    parameterNamesForPatient = parameterNamesForPatient.filter(pn => pn !== 'immunsuppressiva');
  } else {
    parameterNamesForPatient = parameterNames;
  }

  return parameterNamesForPatient.map((name) => {
    const units = [];
    if (name === 'blood_pressure') {
      units.push('sys', 'dys');
    }
    let dataInTimespan;
    if (name === 'symptoms') {
      dataInTimespan = selectedPatient[name].map(symptomList => ({
        name: symptomList.name,
        values: symptomList.values.filter((filterSymptom) =>
          new Date(filterSymptom.timestamp).getTime() >= fromDate &&
          new Date(filterSymptom.timestamp).getTime() <= toDate)
          .map((mapSymptom) => ({
            timestamp: mapSymptom.timestamp,
            value: mapSymptom.value,
          })),
      }));
    } else if (name === 'iceScore') {
      dataInTimespan = selectedPatient.questionnaires
        .filter(evaluation =>
          new Date(evaluation.date).getTime() >= fromDate &&
          new Date(evaluation.date).getTime() <= toDate)
        .map(evaluation => ({
          timestamp: evaluation.date,
          value: calculateQuestionnaireScore(selectedPatient, evaluation.date),
        }));
    } else {
      dataInTimespan = selectedPatient[name].filter(parameter =>
        new Date(parameter.timestamp).getTime() >= fromDate &&
        new Date(parameter.timestamp).getTime() <= toDate);
      if (name === 'wellBeing') {
        dataInTimespan = dataInTimespan.filter(parameter => parameter.value > 0);
      }
    }
    const resultData = dataInTimespan === undefined ? [] : dataInTimespan;

    const weeksAgo = helpers.weeksBetween(fromDate, toDate);
    return {
      data: resultData, units, name, xAxis: 'Date', yAxis: name, weeks: weeksAgo,
    };
  });
};

export const selectLatestEntryDateFromPatients = (state) => {
  if (state.patients && state.patients.patients) {
    return new Date(Math.max(
      ...state.patients.patients.map(e => {
        if (e.latestEntry === null) {
          return noDataAvailableDate;
        }
        const latestDate = new Date(e.latestEntry).getTime();
        if (Number.isNaN(latestDate)) {
          return noDataAvailableDate;
        }
        return latestDate;
      }),
    ));
  }
  return noDataAvailableDate;
};

export const patientsDataSlice = createSlice({
  name: 'patientsData',
  initialState: {
    patients: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    upsertPatientByStudyNumber: (state, action) => {
      state.patients = state.patients
        .filter(patient => patient.study_number !== action.payload.study_number);
      state.patients.push({ ...action.payload.editedPatient });
    },
    updateIsPatientChecked: (state, action) => {
      state.patients = state.patients.map(patient => {
        if (patient.id === action.payload.id) {
          // check if the values have changed at all
          if (patient.isChecked !== action.payload.isChecked ||
            patient.checkedTimestamp !== action.payload.checkedTimestamp) {
            const editedPatient = { ...patient };
            editedPatient.isChecked = action.payload.isChecked;
            editedPatient.checkedTimestamp = action.payload.checkedTimestamp;
            return editedPatient;
          }
          return patient;
        }
        return patient;
      });
    },
    deactivatePatient: (state, action) => {
      state.patients = state.patients.map(
        (patient) => {
          if (patient.study_number === action.payload.study_number) {
            patient.is_active = 0;
          }
          return patient;
        },
      );
    },
    setPatientCommentsChecked: (state, action) => {
      state.patients = state.patients.map((patient) => {
        if (patient.study_number === action.payload.study_number) {
          patient.isCommentsChecked = true;
          patient.commentsCheckedDate = patient.latestComment;
        }
        return patient;
      });
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPatients.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPatients.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.patients = action.payload;
      })
      .addCase(fetchPatients.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const {
  upsertPatientByStudyNumber,
  updateIsPatientChecked,
  deactivatePatient,
  setPatientCommentsChecked,
} = patientsDataSlice.actions;

export default patientsDataSlice.reducer;
