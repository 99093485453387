import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import VitalChartShape from '../../../prop-types/vitalChart';
import SymptomChartShape from '../../../prop-types/symptomChart';
import helpers from '../../../helpers/staticHelperMethods';

const LastChartEntry = (props) => {

  const { t, chart } = props;

  const getDateString = () => {
    if (!chart) {
      return '';
    }
    let dates = [];
    if (chart.name === 'symptoms') {
      chart.data.forEach((data) => {
        data.values.forEach((value) => {
          dates.push(new Date(value.timestamp).getTime());
        });
      });
    } else {
      dates = chart.data.map((data) => new Date(data.timestamp).getTime());
    }
    if (dates.length === 0) {
      return t('patientDetails.no_data_available');
    }
    dates.sort((a, b) => a - b);
    const latestEntry = dates[dates.length - 1];
    const onlyDate = ['symptoms', 'wellBeing', 'immunsuppressiva', 'other_medication', 'iceScore'].includes(chart.name);
    return `${t('patientDetails.last_entry')} ${helpers.getDateString(new Date(latestEntry), t, !onlyDate)}`;
  };

  return (
    <span>{getDateString()}</span>
  );
};

export default withTranslation('common')(LastChartEntry);

LastChartEntry.propTypes = {
  t: PropTypes.func.isRequired,
  chart: PropTypes.oneOfType([
    VitalChartShape,
    SymptomChartShape,
  ]).isRequired,
};
