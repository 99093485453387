import { Table } from 'react-bootstrap';
import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CanvasDraw from 'react-canvas-draw';
import EvaluationCheckboxes from '../evaluation-checkboxes/EvaluationCheckboxes';

const FollowingEvaluation = (props) => {
  const { t } = props;

  return (
    <div className="card col-12 corner-radius">
      <div className="card-body">
        <p>{t('questionnaire.following.title')}</p>
        <Table striped hover>
          <thead>
            <tr>
              <th>{t('questionnaire.following.tableHeaders.followedShape')}</th>
              <th>{t('questionnaire.following.tableHeaders.evaluation')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {
                  props.data.saveString ?
                    <CanvasDraw
                      saveData={props.data.saveString}
                      disabled
                      immediateLoading
                    /> :
                    <p>{t('questionnaire.wasSkippedPlaceholder')}</p>
                }
              </td>
              <td>
                <EvaluationCheckboxes
                  groupName="following"
                  accepted={props.data.accepted}
                  setAccepted={props.setOverwrite}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default withTranslation('common')(FollowingEvaluation);

FollowingEvaluation.propTypes = {
  t: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  setOverwrite: PropTypes.func.isRequired,
};
