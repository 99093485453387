import {
  Image, StyleSheet, Text,
} from '@react-pdf/renderer';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import helpers from '../../../../helpers/staticHelperMethods';


const DownloadChartPdf = (props) => {
  const styles = StyleSheet.create({
    chartTitle: {
      margin: 8,
      textAlign: 'left',
      fontSize: '10',
    },
  });

  const chartPDF = (chartId) => {
    const newCanvas = helpers.createExportableCanvas(chartId);
    return newCanvas.toDataURL('image/png');
  };

  return (
    <>
      <Text style={styles.chartTitle}>{props.chartTitle}</Text>
      <Image src={chartPDF(props.chartName)}/>
    </>
  );
};
export default withTranslation('common')(DownloadChartPdf);

DownloadChartPdf.propTypes = {
  chartName: PropTypes.string.isRequired,
  chartTitle: PropTypes.string.isRequired,
};
