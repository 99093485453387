import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import './Filterbar.scss';

const FilterBar = ({ t, onFilterTextChange }) => (
  <div className="filterBar">
    <FontAwesomeIcon
      id="filterIcon"
      icon={faMagnifyingGlass}
      onClick={() => {
        document.getElementById('filterText').focus();
      }}
    />
    <input
      id="filterText"
      type="text"
      placeholder={t('filterBar.filterText')}
      onChange={onFilterTextChange}
    />
  </div>
);

FilterBar.propTypes = {
  t: PropTypes.func.isRequired,
  onFilterTextChange: PropTypes.func.isRequired,
};

export default withTranslation('common')(FilterBar);
