/**
 * Service to calculate the past days since the transplant
 * @class
 */
class DayCalculatorService {
  /**
   * Calculate the past days since the transplant
   * @param {number} dayOfTransplantation
   * @returns {string} past days since the transplant
   */
  calcDaysSinceTransplant(dayOfTransplantation) {
    const today = new Date();
    const dayTx = Date.parse(dayOfTransplantation);
    const daysDiff = (Math.round((today - dayTx) / (1000 * 60 * 60 * 24))).toString();
    return daysDiff.startsWith('-') ? `- ${daysDiff.slice(1, daysDiff.length)}` : `+ ${daysDiff}`;
  }
}

export default new DayCalculatorService();
