import {
  Document, Page, pdf, StyleSheet, Text, View,
} from '@react-pdf/renderer';
import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectPatientById } from '../../../../redux/patientsSlice';
import DownloadChartPdf from './DownloadChartPdf';
import helpers from '../../../../helpers/staticHelperMethods';


const DownloadButtonPdf = (props) => {

  const { t } = props;
  const { patientId } = useParams();
  const patientIdInt = parseInt(patientId, 10);
  const patientDetails = useSelector(state =>
    selectPatientById(state, patientIdInt));
  const isCART = patientDetails.treatment === 'CAR_T';
  const fromDate = helpers.getDateOnlyString(
    new Date(useSelector(state => state.patientHeader.fromDate)), true,
  );
  const toDate = helpers.getDateOnlyString(
    new Date(useSelector(state => state.patientHeader.toDate)), true,
  );
  const dateRange = `${fromDate} - ${toDate}`;
  const dateRangeFileName = `${fromDate.replaceAll('.', '-')}_${toDate.replaceAll('.', '-')}`;

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    text: {
      textAlign: 'center',
    },
  });

  const overviewPage = () => (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.text}>
          {patientDetails.name} {patientDetails.surname} {patientDetails.birthday}
        </Text>
        <Text style={styles.text}>
          {dateRange}
        </Text>
        <DownloadChartPdf chartName="wellBeing" chartTitle={t('title.wellBeing')}/>
        {!!isCART &&
          <DownloadChartPdf chartName="iceScore" chartTitle={t('title.iceScore')}/>
        }
        {!isCART &&
          <DownloadChartPdf chartName="immunsuppressiva" chartTitle={t('title.immunsuppressiva')}/>
        }
        <DownloadChartPdf chartName="other_medication" chartTitle={t(isCART ? 'title.medication' : 'title.other_medication')}/>
      </View>
    </Page>
  );

  const parametersPage = () => (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <DownloadChartPdf chartName="temperature" chartTitle={t('title.temperature')}/>
        <DownloadChartPdf chartName="weight" chartTitle={t('title.weight')}/>
        <DownloadChartPdf chartName="pulse" chartTitle={t('title.pulse')}/>
      </View>
    </Page>
  );

  const bloodPressureAndSymptomsPage = () => (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <DownloadChartPdf chartName="blood_pressure" chartTitle={t('title.blood_pressure')}/>
        <DownloadChartPdf chartName="symptoms" chartTitle={t('title.symptoms')}/>
      </View>
    </Page>
  );

  const PdfExport = () => ( //
    <Document>
      {overviewPage()}
      {parametersPage()}
      {bloodPressureAndSymptomsPage()}
    </Document>
  );

  const createAndDownloadPdf = async (event) => {
    event.preventDefault(); // prevent page reload
    const blob = await pdf(
      <PdfExport/>,
    ).toBlob();
    saveAs(blob, `export_${patientDetails.surname}_${dateRangeFileName}.pdf`);
  };
  return (
    <div>
      <Button className="downloadPDFButton" onClick={createAndDownloadPdf}><FontAwesomeIcon icon={faDownload} size="lg" /></Button>
    </div>
  );
};
export default withTranslation('common')(DownloadButtonPdf);

DownloadButtonPdf.propTypes = {
  t: PropTypes.func.isRequired,
};
