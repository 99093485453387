import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './CheckIndicator.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCircle as faCircleSolid,
} from '@fortawesome/free-solid-svg-icons';
import { faCircle as faCircleRegular } from '@fortawesome/free-regular-svg-icons';
import { useDispatch } from 'react-redux';
import patientCheckedService from '../../../../../../services/patientCheckedService/patientCheckedService';
import { updateIsPatientChecked } from '../../../../../../redux/patientsSlice';

/**
 * Checkbox that allows you to tick off patients that have already been processed.
 * Is automatically unchecked if the patient has entered data since the last time viewed
 * @param {boolean} props.checked
 * @param {number} props.size
 */
function CheckedIndicator(props) {

  const { size, patientId, isChecked } = props;

  const dispatch = useDispatch();

  const [isHovering, setIsHovering] = useState(false);

  const updateCheckInStore = (checked) => {
    dispatch(updateIsPatientChecked({
      id: patientId,
      isChecked: checked,
      checkedTimestamp: new Date().toISOString(),
    }));
  };



  const toggleCheckIndicator = async () => {
    if (isChecked) {
      await patientCheckedService.setPatientUnchecked(patientId);
    } else {
      await patientCheckedService.setPatientChecked(patientId);
    }
    updateCheckInStore(!isChecked);
  };

  const handleClick = () => {
    // toggle checkedIndicator and synchronize again
    // with the server to display the correct value in any case
    toggleCheckIndicator().then(() => {
      patientCheckedService.getPatientChecked(patientId).then((result) => {
        updateCheckInStore(result.isChecked);
      });
    });
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const circle = (<FontAwesomeIcon
    className="check-circle"
    icon={isChecked ? faCircleSolid : faCircleRegular}
  />);

  const check = isChecked ?
    (<FontAwesomeIcon
      className="fa-inverse"
      size="2xs"
      icon={faCheck}
    />) :
    (<FontAwesomeIcon
      className="check-circle check-fade-in"
      style={{ opacity: isHovering ? 1 : 0 }} // is required for fade in
      size="2xs"
      icon={faCheck}
    />);

  const sizeClass = `fa-${size}x clickable`;

  return (
    <div className={sizeClass}>
      <span
        role="button"
        className="fa-layers fa-fw"
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onFocus={handleMouseOver}
        onBlur={handleMouseOut}
      >
        { circle }
        { check }
      </span>
    </div>
  );
}

export default CheckedIndicator;
CheckedIndicator.defaultProps = {
  size: 3,
};
CheckedIndicator.propTypes = {
  patientId: PropTypes.number.isRequired,
  isChecked: PropTypes.bool.isRequired,
  size: PropTypes.number,
};
