import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import helpers from '../../../../../../helpers/staticHelperMethods';
import './PatientLastEntry.scss';

/**
 * Indicates when the patient last entered data.
 * If the patient was checked, it is displayed when he was checked the last time.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function PatientLastEntry(props) {
  const {
    isChecked, latestEntry, checkedTimestamp, t,
  } = props;

  if (latestEntry === null) {
    const noData = t('patientLastEntry.no_data_available');
    return <span className="font-weight-bold font-size-medium smile-blue">{noData}</span>;
  }

  const latestEntryDate = new Date(latestEntry);
  const latestEntryString = helpers.getDateString(latestEntryDate, t);
  const checkedDateString = ` (${t('patientLastEntry.check')}: ${helpers.getDateString(new Date(checkedTimestamp), t)})`;

  return (
    isChecked ?
      <span className="font-size-medium">
        <span>
          {latestEntryString}
        </span>
        <span className="font-weight-bold smile-blue">
          {checkedDateString}
        </span>
      </span> :
      <span className="font-weight-bold font-size-medium smile-blue">
        {latestEntryString}
      </span>
  );
}

export default withTranslation('common')(PatientLastEntry);

PatientLastEntry.propTypes = {
  t: PropTypes.func.isRequired,
  latestEntry: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  checkedTimestamp: PropTypes.string,
};

PatientLastEntry.defaultProps = {
  checkedTimestamp: '',
};
