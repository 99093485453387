import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import i18next from 'i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './assets/bootstrap.min.css';
import './globalStyles/globalStyles.scss';
import commonDe from './assets/locales/de/translations.json';
import commonEn from './assets/locales/en/translations.json';
import store from './redux/store';
import App from './App';
import AuthHandler from './components/auth/AuthHandler';
import AuthProvider from './components/auth/AuthProvider';
import { FeatureFlagsProvider } from './components/feature-flags/FeatureFlagsProvider';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'de',
  resources: {
    en: {
      common: commonEn,
    },
    de: {
      common: commonDe,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AuthProvider>
    <React.StrictMode>
      <I18nextProvider i18n={i18next}>
        <Provider store={store}>
          <FeatureFlagsProvider>
            <AuthHandler />
            <App />
          </FeatureFlagsProvider>
        </Provider>
      </I18nextProvider>
    </React.StrictMode>
  </AuthProvider>,
);
