import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../header/Header';
import { fetchPatients, selectAllPatients } from '../../redux/patientsSlice';
import { fetchValueConfig } from '../../redux/valueConfigSlice';
import withLoadingNotification from '../common/LoadingNotification';

/**
 * This component is a wrapper around every Main-Component like Dashboard
 * and PatientDetail. It is served to the Router as it'll get passed
 * children (e.g. Dashboard) to display it properly. The Header is
 * always included at the top of every Component that is rendered inside
 * of Layout
 * @returns {function} ReactElement represents Page-Layout with
 * Header on top and children on the bottom.
 * @see Header
 * @class
 * @hideconstructor
 */

const Layout = () => {
  const dispatch = useDispatch();

  const patients = useSelector(selectAllPatients);
  const patientsStatus = useSelector(state => state.patients.status);
  const valueConfig = useSelector(state => state.valueConfig.valueConfig);
  const valueConfigStatus = useSelector(state => state.valueConfig.status);

  const [currentPatients, setCurrentPatients] = useState(patients);

  /**
   * Apply filter and sort on patients array
   */
  const getCurrentPatients = () => {
    if (patients) {
      setCurrentPatients([...patients]);
    }
  };

  useEffect(() => {
    if (patientsStatus === 'idle') {
      dispatch(fetchPatients());
    }
    if (valueConfigStatus === 'idle') {
      dispatch(fetchValueConfig());
    }
  }, [dispatch, patientsStatus, valueConfigStatus]);

  useEffect(() => {
    getCurrentPatients();
  }, [patients]);

  const isLoading = (!valueConfig || !currentPatients ||
    patientsStatus === 'idle' || patientsStatus === 'loading' ||
    valueConfigStatus === 'idle' || valueConfigStatus === 'loading');

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <LoadingNotification isLoading={isLoading} count={30}>
          <Outlet/>
        </LoadingNotification>
      </div>
    </div>
  );
};

const LoadingNotification = withLoadingNotification(({ children }) => children);

export default Layout;
