import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ApiService from '../../services/apiService';

export const FeatureFlagsProviderContext = createContext({
  patientCommentsActive: false,
  displayAllSymptoms: false,
  availableTreatments: [],
  showLastPatientStudyNumberHint: false,
});

export function FeatureFlagsProvider(props) {
  const [featureFlags, setFeatureFlags] = useState(undefined);
  const getFeatureFlags = async () => {
    const featureFlagsApiResponse = await ApiService.getData(
      process.env.REACT_APP_FEATURE_FLAG_ENDPOINT,
    );
    setFeatureFlags(featureFlagsApiResponse.data);
  };
  useEffect(() => {
    getFeatureFlags();
  }, []);

  return (
    featureFlags &&
    <FeatureFlagsProviderContext.Provider value={featureFlags}>
      {props.children}
    </FeatureFlagsProviderContext.Provider>
  );
}

FeatureFlagsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
