import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPills } from '@fortawesome/free-solid-svg-icons';
import helpers from '../../../helpers/staticHelperMethods';
import VitalParameter from '../../../prop-types/vitalParameter';
import './MedicationAdherence.scss';
import ValueToolTip from '../value-tool-tip/ValueToolTip';
import Treatment from '../../../prop-types/treatment';
import e2eTestIds from '../../e2eTestIds';

const MedicationAdherence = (props) => {

  const {
    immunsuppressiva, otherMedication, treatmentMethod, t,
  } = props;

  const immunTooltipId = uuidv4();
  const otherTooltipId = uuidv4();
  const isVal = helpers.getMappedImmunsuppressivaString(immunsuppressiva.value, t);
  const otherVal = helpers.getMappedOtherMedicationString(otherMedication.value, t);

  return (immunsuppressiva.value === -1 || otherMedication === -1) ? <span /> :
    (
      <div>
        {(treatmentMethod === 'ALLO_TX') &&
        <span className="va" data-tip data-for={immunTooltipId}>
          <div className="fa-layers fa-fw pillIcon pill-icon-margin">
            <FontAwesomeIcon icon={faPills} />
          </div>
          <span className="medication-text-style on-hover-blue">
            {t('medication_adherence.is')}
          </span>
          <span className="medication-value-style on-hover-blue" data-testid={e2eTestIds.components.medicationAdherence.immuneSuppressive}>
            &nbsp;{isVal}
          </span>
          <ValueToolTip
            tooltipId={immunTooltipId}
            name={t('title.immunsuppressiva')}
            timestamp={immunsuppressiva.updatedAt}
          />
        </span>
        }
        <span className="OtherMedicationTooltip on-hover-blue" data-tip data-for={otherTooltipId}>
          <div className="fa-layers fa-fw pillIcon pill-icon-margin">
            <FontAwesomeIcon icon={faPills} />
          </div>
          <span className="medication-text-style on-hover-blue">
            {treatmentMethod === 'ALLO_TX' ? t('medication_adherence.otherM') : t('medication_adherence.medication_short')}
          </span>
          <span className="medication-value-style on-hover-blue" data-testid={e2eTestIds.components.medicationAdherence.otherMedication}>
            &nbsp;{otherVal}
          </span>
          <ValueToolTip
            tooltipId={otherTooltipId}
            name={treatmentMethod === 'ALLO_TX' ? t('title.other_medication') : t('title.medication')}
            timestamp={otherMedication.updatedAt}
          />
        </span>
      </div>
    );
};

export default withTranslation('common')(MedicationAdherence);

MedicationAdherence.propTypes = {
  immunsuppressiva: VitalParameter.isRequired,
  otherMedication: VitalParameter.isRequired,
  treatmentMethod: Treatment.isRequired,
  t: PropTypes.func.isRequired,
};
