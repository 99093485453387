import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import './HandWritingImage.scoped.scss';
import { Table } from 'react-bootstrap';
import EvaluationCheckboxes from '../evaluation-checkboxes/EvaluationCheckboxes';
import HandWritingImage from './HandWritingImage';
import helpers from '../../../helpers/staticHelperMethods';
import './HandWritingEvalulation.scoped.scss';

const HandWritingEvaluation = (props) => {
  const { t } = props;

  const [showHandwritingHistory, setShowHandwritingHistory] = React.useState(false);

  const additionalImgs = props.data.additionalHandwritingSrcs;

  const toggleShowHandWriting = () => {
    setShowHandwritingHistory(!showHandwritingHistory);
  };

  return (
    <div className="card col-12 corner-radius">
      <div className="card-body">
        <p>{t('questionnaire.handWriting.title')}</p>
        <div className="handWritingTableContainer">
          <Table striped hover>
            <thead>
              <tr>
                {props.data.originalImgSrc &&
                  <th className="handWritingTableHeaderCentered">{t('questionnaire.handWriting.tableHeaders.originalHandWriting', {
                    date: helpers.getDateString(
                      new Date(props.data.originalImgSrc.imgDate),
                      t,
                      false,
                      true,
                    ),
                  })}
                  </th>
                }
                {showHandwritingHistory &&
                <>
                  {
                    additionalImgs.map(addImg =>
                      <th key={addImg.imgDate} className="handWritingTableHeaderCentered">
                        {t('questionnaire.handWriting.tableHeaders.previousHandWriting', {
                          date: helpers.getDateString(new Date(addImg.imgDate), t, false, true),
                        })}
                      </th>)
                  }
                </>
                }
                <th className="handWritingTableHeaderCentered">{t('questionnaire.handWriting.tableHeaders.currentHandWriting')}</th>
                <th>{t('questionnaire.handWriting.tableHeaders.evaluation')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {props.data.originalImgSrc &&
                  <td height="1px">
                    <HandWritingImage imageSrc={props.data.originalImgSrc.imgSrc}/>
                  </td>
                }
                {showHandwritingHistory &&
                <>
                  {
                    additionalImgs.map(addImg => (
                      <td key={addImg.imgDate}>
                        <HandWritingImage imageSrc={addImg.imgSrc}/>
                      </td>
                    ))
                  }
                </>
                }
                <td height="1px">
                  <HandWritingImage imageSrc={props.data.currentImgSrc}/>
                </td>
                <td>
                  <EvaluationCheckboxes
                    groupName="handWriting"
                    accepted={props.data.accepted}
                    setAccepted={props.setOverwrite}
                  />
                  <div className="additionalHandWritingCheckboxContainer">
                    <input type="checkbox" onChange={toggleShowHandWriting}/>
                    <label>
                      <span>
                        {t('questionnaire.handWriting.show_additional_handWritings')}
                      </span>
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default withTranslation('common')(HandWritingEvaluation);

HandWritingEvaluation.propTypes = {
  t: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  setOverwrite: PropTypes.func.isRequired,
};
