import React from 'react';
import './EditHeader.scss';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import HeaderContainer from '../../wrapper/header/HeaderContainer';

/**
 * This component is always displayed it communicates with
 * TitleService to show a Title corresponding to page content.
 * Right-handed is the logged-in user and a logout button
 * @hideconstructor
 * @returns {function} ReactElement represents
 * Header of the page
 * @see TitleService
 */
function EditHeader(props) {

  const {
    t, name, surname,
  } = props;
  const headerName = `${surname || '?'}, ${name || '?'}`;

  return (
    <HeaderContainer>
      <span className="headerNameContainer">{headerName}
      </span>
      <span> {t('editHeader.information')}</span>
    </HeaderContainer>
  );
}

EditHeader.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.element.isRequired,
  surname: PropTypes.element.isRequired,
};

export default withTranslation('common')(EditHeader);


