import React, { useCallback, useEffect, useState } from 'react';
import Keycloak from 'keycloak-js';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import ApiService from '../../services/apiService';

const authCookieName = 'authorization';

function AuthProvider({ children }) {
  const [keycloak, setKeycloak] = useState(undefined);

  // Load keycloak config from backend
  useEffect(() => {
    const getKeycloakConfig = async () => {
      const keycloakConfig = await ApiService.getData(
        process.env.REACT_APP_KEYCLOAK_CONFIG_ENDPOINT,
      );
      setKeycloak(new Keycloak(keycloakConfig.data));
    };
    getKeycloakConfig();
  }, []);

  // Set cookie if token changes
  const onTokensChange = useCallback(({ token }) => {
    if (token) {
      Cookies.set(authCookieName, token, {
        secure: true,
      });
    } else {
      Cookies.remove(authCookieName);
    }
  }, []);

  return (
    keycloak ?
      <ReactKeycloakProvider
        authClient={keycloak}
        onTokens={onTokensChange}
      >{children}
      </ReactKeycloakProvider>
      : null
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
