import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import PatientChart from './patient-chart/PatientChart';
import TitleService from '../../services/title/titleService';
import ExcelDownloader from './excel-downloader/ExcelDownloader';
import './PatientDetails.scss';
import { fetchPatients, selectParameterInPeriodFromPatientById, selectPatientById } from '../../redux/patientsSlice';
import { fetchValueConfig } from '../../redux/valueConfigSlice';
import LastChartEntry from './last-chart-entry/LastChartEntry';
import Error from '../error/Error';
import helpers from '../../helpers/staticHelperMethods';


/**
 * Component to display the charts with development of symptoms and vital parameters of patient
 * @hideconstructor
 */
const PatientDetails = (props) => {

  const { t } = props;

  const { patientId } = useParams();

  const patientIdInt = parseInt(patientId, 10);

  const dispatch = useDispatch();

  const fromDate = useSelector(state => state.patientHeader.fromDate);
  const toDate = useSelector(state => state.patientHeader.toDate);

  const valueConfig = useSelector(state => state.valueConfig);

  const chartData = useSelector(state =>
    selectParameterInPeriodFromPatientById(state, patientIdInt, fromDate, toDate));

  const patientDetails = useSelector(state =>
    selectPatientById(state, patientIdInt));

  const patientsStatus = useSelector(state => state.patients.status);
  const valueConfigStatus = useSelector(state => state.valueConfig.status);

  useEffect(() => {
    if (patientDetails) {
      // change title
      TitleService.setTitle(`${patientDetails.study_number}`);
      window.scrollTo(0, 0);
    }
    // trigger event for header generation
    const patientDetailPageEvent = new CustomEvent('patientDetailPageEvent', { detail: patientDetails });
    document.dispatchEvent(patientDetailPageEvent);
  }, [patientDetails]);

  useEffect(() => {
    if (patientsStatus === 'idle') {
      dispatch(fetchPatients());
    }
    if (valueConfigStatus === 'idle') {
      dispatch(fetchValueConfig());
    }
  }, [dispatch, patientsStatus]);

  const saveChart = (chartId) => {
    const newCanvas = helpers.createExportableCanvas(chartId);
    newCanvas.toBlob((blob) => {
      saveAs(blob, `${patientDetails.study_number}_${chartId}.png`);
    });
  };

  const getLabel = (treatment, chartName) => ((treatment === 'CAR_T' && chartName === 'other_medication') ? t('title.medication') : t(`title.${chartName}`));


  const renderCharts = (data) => data.map(chart => (
    <div className="card col-12 patient-details-card">
      <div className="card-body">
        <div className="row chart-header">
          <div className="col-xl-6">
            <h3>
              {getLabel(patientDetails.treatment, chart.name)}
              <span id="last-entry">
                <LastChartEntry chart={chart}/>
              </span>
            </h3>
          </div>
          <div className="col-xl-6">
            {patientDetails.monitored ?
              <div>
                <ExcelDownloader chart={chart} patientDetails={patientDetails}/>
                <Button id="image-downloader" className="btn-light" onClick={() => saveChart(chart.name)}>
                  <FontAwesomeIcon icon={faDownload} size="lg"/>
                </Button>
              </div> :
              <div/>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12">
            {patientDetails.monitored ?
              <PatientChart
                key={chart.name}
                chart={chart}
                fromDate={fromDate}
                toDate={toDate}
                valueConfig={valueConfig}
                chartName={getLabel(patientDetails.treatment, chart.name)}
                treatment={patientDetails.treatment}
              /> :
              t('shared.monitoring_disabled')
            }
          </div>
        </div>
      </div>
    </div>
  ));

  const error = <Error code={404} hint={`${t('patientDetails.error_hint')} ${patientId}`}/>;
  if (patientsStatus !== 'succeeded' && valueConfigStatus !== 'succeeded') { // before requests succeeded
    return (
      <div className="centered chartBeginning">
        <Skeleton count={20}/>
      </div>
    );
  } else {
    // eslint-disable-next-line no-return-assign
    return (
      <div className="chartBeginning">
        <div id="charts">
          {(chartData && chartData.length !== 0) ? renderCharts(chartData) : error}
        </div>
      </div>
    );
  }
};
export default withTranslation('common')(PatientDetails);

PatientDetails.propTypes = {
  t: PropTypes.func.isRequired,
};
