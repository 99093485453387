import React from 'react';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import patientShape from '../../../prop-types/patient';

const ExcelDownloader = (props) => {

  const { chart, patientDetails } = props;

  const linksStyle = {
    color: '#545454',
  };

  const fileName = `${patientDetails.study_number}_${chart.name}.csv`;

  const data = () => {
    if (chart.name !== 'symptoms') {
      return chart.data;
    }

    const names = chart.data.map(item => item.name);
    const columns = ['timestamps', ...names];
    const timestampDict = {};
    chart.data.forEach((dataObj) => {
      dataObj.values.forEach((valueObj) => {
        if (timestampDict[valueObj.timestamp] === undefined) {
          timestampDict[valueObj.timestamp] = [];
        }
        timestampDict[valueObj.timestamp].push(valueObj.value);
      });
    });
    const result = [columns];
    // eslint-disable-next-line no-restricted-syntax
    for (const [time, values] of Object.entries(timestampDict)) {
      result.push([time, ...values]);
    }
    return result;
  };
  return (
    <Button id="excel-downloader" className="btn-light">
      <CSVLink id="csv-link" data={data()} style={linksStyle} filename={fileName} separator=";">
        <FontAwesomeIcon
          size="lg"
          icon={faFileCsv}
        />
      </CSVLink>
    </Button>
  );
};

export default withTranslation('common')(ExcelDownloader);

ExcelDownloader.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  chart: PropTypes.any.isRequired,
  patientDetails: patientShape.isRequired,
};
