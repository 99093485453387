import { createSlice } from '@reduxjs/toolkit';

export const newPatientDataSlice = createSlice({
  name: 'newPatientDataSlice',
  initialState: {
    newPatientData: false,
  },
  reducers: {
    updateNewPatientsData: (state, action) => {
      state.newPatientData = action.payload.newPatientData;
    },
  },
});

export const { updateNewPatientsData } = newPatientDataSlice.actions;

export default newPatientDataSlice.reducer;
