import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './PatientHeader.scss';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import DownloadButtonPdf from './download-button-pdf/DownloadButtonPdf';
import DayCalculator from '../../common/DayCalculator';
import MedicationAdherence from '../../common/medication-adherence/MedicationAdherence';
import TimespanDropdown from './timespan-dropdown/TimespanDropdown';
import HeaderContainer from '../../wrapper/header/HeaderContainer';
import helpers from '../../../helpers/staticHelperMethods';
import TreatmentInfo from '../../common/treatment-info/TreatmentInfo';

/**
 * This component is always displayed it communicates with
 * TitleService to show a Title corresponding to page content.
 * Right-handed is the logged-in user and a logout button
 * @hideconstructor
 * @returns {function} ReactElement represents
 * Header of the page
 * @see TitleService
 */
function PatientHeader(props) {
  const {
    t, patientDetailData,
  } = props;

  // for some reason there is an empty object instead of
  // undefined when no data was found. Feel free to improve this
  // https://stackoverflow.com/a/679937
  const isPatientDetailData = !(Object.keys(patientDetailData).length === 0);
  // If there is no patient data, render only the back button
  if (!isPatientDetailData) {
    return (<HeaderContainer/>);
  }

  const {
    name,
    surname,
    immunsuppressiva,
    immunsuppressiva_flag: immunsuppressivaFlag,
    birthday,
    other_medication: otherMedication,
    id,
    transplant_date: transplantDate,
    sex,
    phone,
    mode,
    monitored,
  } = patientDetailData;

  const daysSince = transplantDate ?
    (<DayCalculator
      id={id}
      dayOfTransplantation={transplantDate}
      tooltipFlag={false}
    />) : <div/>;

  const age = helpers.getAge(birthday);

  const immun = immunsuppressiva.length !== 0
    ? immunsuppressiva[0]
    : { value: -1, timestamp: new Date() };

  const otherMedicationValue = otherMedication.length !== 0
    ? otherMedication[0]
    : { value: -1, timestamp: new Date() };

  const surnameName = `${surname || '?'}, ${name || '?'}`;

  const sexIcon = helpers.getSexIcon(sex);
  const homeIcon = helpers.getHomeIcon(mode);

  return (
    <HeaderContainer>
      <span className="patientName">{surnameName}
        <sup>{sexIcon}</sup>
      </span>
      {age ? <span className="patientAge">{age} {t('patientHeader.age')} </span> : null}
      <div className="treatment">
        <TreatmentInfo treatment={patientDetailData.treatment}/>
      </div>
      <span className="daySince"> {daysSince} </span>
      <span className="homeIcon">{homeIcon}</span>
      <MedicationAdherence
        immunsuppressivaFlag={immunsuppressivaFlag}
        immunsuppressiva={immun}
        otherMedication={otherMedicationValue}
      />
      {phone ?
        <span className="phone">
          <FontAwesomeIcon size="1x" icon={faPhone}/> {phone}
        </span>
        : null}
      {monitored ?
        <div className="pdfDownloadButton">
          <DownloadButtonPdf/>
        </div> :
        <div/>
      }
      {monitored ?
        <div className="timespan">
          <TimespanDropdown/>
        </div> :
        <div/>
      }
    </HeaderContainer>
  );
}


PatientHeader.propTypes = {
  t: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  patientDetailData: PropTypes.object.isRequired,
};

export default withTranslation('common')(PatientHeader);


