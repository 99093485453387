import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';
import './Header.scss';
import PageHeader from '../header-detail/PageHeader';
import e2eTestIds from '../e2eTestIds';
import useAuth from '../../hooks/useAuth';

/**
 * This component is always displayed it communicates with
 * TitleService to show a Title corresponding to page content.
 * Right handed is the logged in user and a logout button
 */
const Header = () => {
  const { logout, user } = useAuth();

  const headerText = 'SMILe Care';
  const logoutText = 'Logout';

  return (
    <div className="headerNavbar">
      <nav className="navbar navbar-light bg-light navbar-expand-sm sticky-top">
        <span className="navbar-brand">
          <Link to="/" className="noTextDecoration">{headerText}</Link>
        </span>
        <ul className="navbar-nav ml-auto">
          <li className="navbar-text px-5" id="user">
            <FontAwesomeIcon size="1x" icon={faUser}/>
            <span data-testid={e2eTestIds.header.user}> {user?.username} </span>
          </li>
          <li className="nav-item">
            <div
              className="nav-link"
              role="button"
              onClick={logout}
            >
              <FontAwesomeIcon icon={faSignOut}/>
              <span> {logoutText} </span>
            </div>
          </li>
        </ul>
      </nav>
      <nav className="">
        <PageHeader/>
      </nav>
    </div>
  );
};

export default Header;

