import PropTypes from 'prop-types';

const valueLimitDefinitionBloodPressure = PropTypes.shape({

  max: PropTypes.shape({
    systolic: PropTypes.number.isRequired,
    diastolic: PropTypes.number.isRequired,
  }),
  min: PropTypes.shape({
    systolic: PropTypes.number.isRequired,
    diastolic: PropTypes.number.isRequired,
  }),
});

export default valueLimitDefinitionBloodPressure;
