import { useEffect, useState } from 'react';
import useFeatureFlags from './useFeatureFlags';
import ApiService from '../../services/apiService';
import errors from '../../errors';

async function getLastPatientStudyNumber() {
  const endpoint = process.env.REACT_APP_GET_LAST_PATIENT_STUDY_NUMBER;
  return ApiService.getData(endpoint)
    .then((res) => {
      if (res.status === 200) {
        return Promise.resolve(res.data);
      } else if (res.status === 204) {
        return Promise.resolve(undefined);
      } else {
        return Promise.reject(new Error(errors.serverErr));
      }
    })
    .catch((e) => {
      console.log(e);
      Promise.reject(new Error(errors.faultyUrlOrEndpoint));
    });
}

export default function useShowLastPatientStudyNumberHint() {
  const { showLastPatientStudyNumberHint } = useFeatureFlags();
  const [lastPatientStudyNumber, setLastPatientStudyNumber] = useState(undefined);
  useEffect(() => {
    if (showLastPatientStudyNumberHint) {
      getLastPatientStudyNumber().then(setLastPatientStudyNumber);
    } else {
      setLastPatientStudyNumber(undefined);
    }
  }, []);
  return lastPatientStudyNumber;
}


