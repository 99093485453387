/**
 * Service that takes care of handling the scroll state
 * @class
 */
class ScrollStateService {

  /**
   * Save current Y coordinate when link is clicked.
   * This is used to return to the previous position on the dashboard.
   */
  saveScrollState() {
    sessionStorage.setItem('scrollPosition', `${window.scrollY}`);
  }

  /**
   * Returns the last saved scroll state. Returns 0 if no scroll state was saved.
   * @returns {number}
   */
  getScrollState() {
    const scrollPosition = sessionStorage.getItem('scrollPosition');
    if (scrollPosition) {
      sessionStorage.removeItem('scrollPosition');
      return parseInt(scrollPosition, 10);
    } else {
      return 0;
    }
  }
}

export default new ScrollStateService();
