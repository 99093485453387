import React from 'react';
import PropTypes from 'prop-types';
import './TrendIndicator.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowDown, faArrowLeft, faArrowUp, faQuestion,
} from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';

/** @classdesc creates an arrow that shows the trend of a
 * given symptom (e.g. 8 -> 10 Results to arrow-up)
 * @param {number} props.max Maximal Volume of Bar (mostly 10)
 * @param {number} props.min Minimal Volume of Bar (always 0)
 * @param {number} props.currentValue Value that should be displayed (between min-max)
 * @param {number} props.previousValue Displayed Value right before props.currentValue
 * @returns {function}  JSX-Code rendering to an Upside/Downside Arrow displaying trend of symptom
 * @class
 * @hideconstructor
 */
const trendIndicator = (props) => {
  const {
    t,
    currentValue,
    previousValue,
    max,
    min,
    isObsolete,
  } = props;
  let arrow;

  if (isObsolete) {
    arrow = <FontAwesomeIcon icon={faQuestion}/>;
  } else if (currentValue === previousValue) {
    arrow = <FontAwesomeIcon icon={faArrowLeft}/>;
  } else if (currentValue < previousValue) {
    arrow = <FontAwesomeIcon icon={faArrowDown}/>;
  } else {
    arrow = <FontAwesomeIcon icon={faArrowUp}/>;
  }

  const isBinary = max === 1;
  const className = isBinary ? 'binaryLabel' : '';

  return (
    <div className="TrendIndicator">
      <div className={className}>{isBinary ? t('dashboard.binarySymptomYes') : max}</div>
      {arrow}
      <div className={className}>{isBinary ? t('dashboard.binarySymptomNo') : min}</div>
    </div>
  );
};

export default withTranslation('common')(trendIndicator);

trendIndicator.propTypes = {
  isObsolete: PropTypes.bool.isRequired,
  currentValue: PropTypes.number.isRequired,
  previousValue: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};
