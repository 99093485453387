import PropTypes from 'prop-types';

const valueConfigShape = PropTypes.shape({
  method: PropTypes.oneOf(['CUT_OFF_VALUES', 'BLOOD_PRESSURE', 'DIFFERENCE_OVER_TIME']).isRequired,
  mode: PropTypes.oneOf(['HOME', 'HOSPITAL']).isRequired,
  levels: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
  })),
});

export default valueConfigShape;
