import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import ValueToolTip from '../../../../../../common/value-tool-tip/ValueToolTip';

const Age = (props) => {

  const { birthday, t } = props;

  const age = () => {
    if (birthday) {
      const bArray = birthday.split('.');
      // birthday format: dd.mm.yyyy
      // overwrite year, month and day in that order to work probably
      const birthdayDate = new Date();
      birthdayDate.setUTCFullYear(bArray[2]); // year
      birthdayDate.setUTCMonth(bArray[1], 0); // month with day 0 -> no overflow possible!
      birthdayDate.setUTCDate(bArray[0]); // day
      const ageDiff = new Date(Date.now() - birthdayDate.getTime());
      return Math.abs(ageDiff.getUTCFullYear() - 1970);
    }
    return 0;
  };

  const tooltipId = uuidv4();
  if (birthday) {
    return (
      <span className="value-font-size unit-style on-hover-blue" data-tip data-for={tooltipId}>
        {age()}&nbsp;{t('summaryContent.year')}
        <ValueToolTip
          tooltipId={tooltipId}
          name={t('title.birthday')}
          formatDate={false}
          timestamp={birthday}
        />
      </span>);
  }
  return (<span/>);
};

export default withTranslation('common')(Age);

Age.propTypes = {
  birthday: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Age.defaultProps = {
  birthday: undefined,
};
