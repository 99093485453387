import errors from '../../errors';
import ApiService from '../apiService';


class NewDataService {

  constructor() {
    this.allPatientsNewDataEndpoint = process.env.REACT_APP_ALL_PATIENTS_NEW_DATA_ENDPOINT;
  }

  /**
     * Try to request if a patient is checked
     * @param {string} date
     * @returns {Promise} isPatientChecked
     */
  async newData(date) {
    // throw error if the id is null or undefined
    if (!date) {
      return Promise.reject(new Error(errors.noDateErr));
    }

    const endpoint = this.allPatientsNewDataEndpoint.replace('%from', date);
    return ApiService.getData(endpoint)
      .then((res) => {
        if (!res) return Promise.reject(new Error(errors.noNewDataResponseErr));
        return Promise.resolve(res.data);
      })
      .catch(() => Promise.reject(new Error(`${errors.noNewDataResponseErr} ${date}`)));
  }
}

export default new NewDataService();
