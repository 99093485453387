import React from 'react';
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import VitalChartShape from '../../../prop-types/vitalChart';
import SymptomChartShape from '../../../prop-types/symptomChart';
import errors from '../../../errors';
import chartConfigService from '../../../services/chartConfigService/chartConfigService';
import useDisplayedSymptoms from '../../feature-flags/useDisplayedSymptoms';
import treatmentShape from '../../../prop-types/treatment';

// This palette was created via https://mokole.com/palette.html
const symptomNameToLineColor = {
  DIARRHOEA: 'silver',
  PAIN: 'darkolivegreen',
  SIGNS_BLEEDING: 'saddlebrown',
  SKIN_RASH: 'darkslateblue',
  PROBLEMS_SWALLOWING: 'mediumseagreen',
  SORE_MOUTH: 'darkcyan',
  NAUSEA: 'yellowgreen',
  VOMITING: 'darkblue',
  SHORT_BREATH: 'red',
  BURNING_URINATION: 'orange',
  FATIGUE: 'yellow',
  COUGH: 'lawngreen',
  DECREASED_APPETITE: 'mediumorchid',
  HEADACHE: 'springgreen',
  VERTIGO: 'aqua',
  TREMOR: 'deepskyblue',
  TREMOR_SPASMS: 'blue',
  CONFUSION: 'fuchsia',
  YELLOW_COLOR: 'dodgerblue',
  PLAQUE_MOUTH: 'khaki',
  NUMBNESS: 'salmon',

  // these colors can be used if new symptoms need to be added
  // this _might_ keep backwards-compatibility of generated plot-images a bit longer
  UNUSED: 'plum',
  UNUSED_2: 'deeppink',
};

/**
 * Component displays charts
 * @hideconstructor
 */
const PatientChart = (props) => {

  const {
    chart, fromDate, toDate, valueConfig, chartName, t, treatment,
  } = props;

  const getLabel = (chartProp) => {
    const label = [];
    if (chartProp.name === 'symptoms') {
      const symptom = chartProp.data[0];
      symptom.values.forEach(symptomData => {
        label.push(symptomData.timestamp);
      });
      return label;
    }
    return chartProp.data.map((chartData) => chartData.timestamp);
  };

  /**
   * This method process the data in a way chart.js can work with.
   * @returns {*}
   * @param chartProp
   */
  const getData = (chartProp) => {
    // blood pressure chart
    if (chartProp.name === 'blood_pressure') {
      const sys = [];
      const dis = [];
      chartProp.data.forEach((chartData => {
        sys.push(chartData.systolic);
        dis.push(chartData.diastolic);
      }));
      return [
        {
          label: t('vitals.sys'),
          data: sys,
          backgroundColor: 'rgb(0, 0, 255)',
          borderColor: 'rgb(0, 0, 255)',
        },
        {
          label: t('vitals.dys'),
          data: dis,
          backgroundColor: 'rgb(255, 0, 0)',
          borderColor: 'rgb(255, 0, 0)',
        },
      ];
    }

    // symptom chart
    if (chartProp.name === 'symptoms') {
      const displayedSymptoms = useDisplayedSymptoms(
        chartProp.data,
        valueConfig.valueConfig,
        treatment,
      );
      return displayedSymptoms
        .map((symptom) => {
          const symptomChartData = symptom.values.map(symptomData => symptomData.value);
          const color = symptomNameToLineColor[symptom.name] ?? 'rgb(84,84,84)';
          return {
            label: t(`symptoms.${symptom.name}`),
            data: symptomChartData,
            backgroundColor: color,
            borderColor: color,
          };
        });
    }
    // Attention, this code could be illegal as long as the application is not certified as
    // a medical device because data is color coded
    /* immunsuppressiva and other medication
    if (chartProp.name === 'immunsuppressiva' || chartProp.name === 'other_medication') {
      let pointBackgroundColors = [];
      if (chartProp.name === 'immunsuppressiva') {
        pointBackgroundColors = chartProp.data.map((chartData) => {
          if (chartData.value === 2) return 'Green';
          if (chartData.value === 1) return 'Red';
          return 'Grey';
        });
      }
      if (chartProp.name === 'other_medication') {
        pointBackgroundColors = chartProp.data.map((chartData) => {
          if (chartData.value === 3) return 'Green';
          if (chartData.value === 2) return 'Orange';
          if (chartData.value === 1) return 'Red';
          return 'Grey';
        });
      }
      const data = chartProp.data.map((chartData) => (chartData.value));
      return [{
        label: t(`title.${chart.name}`),
        data,
        backgroundColor: 'rgb(84,84,84)',
        borderColor: 'rgba(0,0,0,0)',
        pointBackgroundColor: pointBackgroundColors,
      }];
    }
    */
    const isMedication = (chartProp.name === 'immunsuppressiva' || chartProp.name === 'other_medication');
    const borderColor = isMedication ? 'rgb(0,0,0,0)' : 'rgb(84,84,84)';
    // other charts
    const data = chartProp.data.map((chartData) => (chartData.value));
    return [{
      label: chartName,
      data,
      backgroundColor: 'rgb(84,84,84)',
      borderColor,
    }];
  };

  /**
   * This method is used to create a LineChart
   * @returns {function} ReactElement Line from ChartJs
   * @param chartProp
   */
  const createLineChart = (chartProp) => {
    const data = {
      labels: getLabel(chartProp),
      datasets: getData(chartProp),
    };
    const plugins = chartConfigService.getChartPlugins(chartProp.name);
    const optionsObj = chartConfigService.getChartOptions(chartProp, fromDate, toDate, valueConfig);

    // calculate heights for charts -> other_medication and immunsuppressvia are less high
    const height = (chartProp.name === 'other_medication' ||
        chartProp.name === 'immunsuppressiva') ? '10%' : '33%';
    return (
      <Line
        id={chartProp.name}
        plugins={plugins}
        options={optionsObj}
        data={data}
        width="100%"
        height={height}
      />
    );
  };

  const buildChart = (chartProp) => {
    if (!chartProp) throw new Error(errors.missingDataErr);
    return (
      <div>
        {createLineChart(chartProp)}
      </div>
    );
  };

  return (buildChart(chart));
};

export default withTranslation('common')(PatientChart);

PatientChart.propTypes = {
  chart: PropTypes.oneOfType([
    VitalChartShape,
    SymptomChartShape,
  ]).isRequired,
  fromDate: PropTypes.number,
  toDate: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  valueConfig: PropTypes.any,
  chartName: PropTypes.string.isRequired,
  treatment: treatmentShape.isRequired,
  t: PropTypes.func.isRequired,
};
