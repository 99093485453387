import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SymptomShape from '../../../../../../prop-types/symptom';
import symptomCardLimitsShape from '../../../../../../prop-types/symptomCardLimits';
import SeverityIndicator from '../severity-indicator/SeverityIndicator';
import './SymptomCard.scss';
import e2eTestIds from '../../../../../e2eTestIds';

/**
 * Displays a flex grid row for one patient
 * and personal information (name, age, gender)
 * can represent (like min, max) staged in different serverity levels
 * @example <SymptomCard key={symptom.name} symptom={symptom}
 * valueLimits={valueLimits[symptom.name]} />
 *
 * @returns {function} ReactElement containing the severity-indicator of the symptom
 * (e.g.) the bar, labels and severity indicators represented as arrows
 * @hideconstructor
 * @param props
 */
const SymptomCard = (props) => {
  const {
    t,
    symptom,
    valueLimits,
  } = props;

  const max = valueLimits?.inputType.maxValue ?? 1;
  const min = valueLimits?.inputType.minValue ?? 0;
  const [symptomName, setSymptomName] = useState('');
  const [currentValue, setCurrentValue] = useState(0);
  const [previousValue, setPreviousValue] = useState(0);
  const [currentValueTimestamp, setCurrentValueTimestamp] = useState(0);
  const [isObsolete, setIsObsolete] = useState(false);
  const [previousValueTimestamp, setPreviousValueTimestamp] = useState(0);

  const isValueObsolete = (obsoleteTime, timestamp) => {
    const diff = new Date().getTime() - timestamp;
    return diff > (obsoleteTime * 1000);
  };

  const isValueFromSameDay = (value) => {
    const currentDay = new Date().getDay();
    return currentDay === value.getDay();
  };

  useEffect(() => {
    // Calculate States
    let tmpPreviousValueTimestamp = 0;
    let tmpCurrentValueTimestamp = 0;
    let tmpCurrentValue = 0;
    let tmpPreviousValue = 0;

    let severityValues = [];
    if (symptom.values.length >= 2) {
      severityValues = [symptom.values[0], symptom.values[1]];
    } else if (symptom.values.length === 1) {
      severityValues = [symptom.values[0]];
    }

    switch (severityValues.length) {
    case 0:
      break;
    case 1:
      tmpCurrentValue = severityValues[0].value;
      tmpCurrentValueTimestamp = new Date(severityValues[0].updatedAt).getTime();
      break;
    case 2:
      tmpCurrentValue = severityValues[0].value;
      tmpCurrentValueTimestamp = new Date(severityValues[0].updatedAt).getTime();
      tmpPreviousValue = severityValues[1].value;
      tmpPreviousValueTimestamp = new Date(severityValues[1].updatedAt);

      if (tmpCurrentValue === 0 && isValueFromSameDay(tmpPreviousValueTimestamp)) {
        setCurrentValue(tmpPreviousValue);
      }
      break;
    default:
      break;
    }
    // Set states
    setSymptomName(t('symptoms.'.concat(symptom.name)));
    setCurrentValue(tmpCurrentValue);
    setCurrentValueTimestamp(tmpCurrentValueTimestamp);
    setPreviousValue(tmpPreviousValue);
    setPreviousValueTimestamp(tmpPreviousValueTimestamp !== 0 ?
      tmpPreviousValueTimestamp.getTime() : 0);
    setIsObsolete(isValueObsolete(valueLimits?.obsolete ?? 1, tmpCurrentValueTimestamp));
  }, []);

  // eslint-disable-next-line max-len
  const symptomCardClass = isObsolete || currentValue === 0 ? 'symptoms-text symptoms-card-opacity' : 'symptoms-text';

  return (
    <div className="col">
      <div className="symptomcard">
        <p className={symptomCardClass}> {symptomName}</p>
        <SeverityIndicator
          symptom={symptomName}
          min={min}
          max={max}
          currentValue={isObsolete ? 0 : currentValue}
          currentValueTimestamp={currentValueTimestamp}
          previousValue={isObsolete ? 0 : previousValue}
          previousValueTimestamp={previousValueTimestamp}
          barColor="#545454"
          isObsolete={isObsolete}
          data-testid={e2eTestIds.dashboard.patientCard.symptom(symptom.name)}
        />
      </div>
    </div>
  );
};

export default withTranslation('common')(SymptomCard);

SymptomCard.propTypes = {
  symptom: SymptomShape.isRequired,
  valueLimits: symptomCardLimitsShape.isRequired,
  t: PropTypes.func.isRequired,
};
