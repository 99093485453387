import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigate, useParams } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import {
  deactivatePatient,
  fetchPatients,
  selectPatientByStudyNumber,
  upsertPatientByStudyNumber,
} from '../../redux/patientsSlice';
import ApiService from '../../services/apiService';
import './EditComponent.scss';
import TitleService from '../../services/title/titleService';
import Error from '../error/Error';
import PatientInformationForm from './PatientInformationForm';
import e2eTestIds from '../e2eTestIds';
import useAvailableTreatments from '../feature-flags/useAvailableTreatments';

function EditComponent(props) {

  const { t } = props;
  const { studyNumber } = useParams();

  const dispatch = useDispatch();
  const currentPatient = {
    ...useSelector(state =>
      selectPatientByStudyNumber(state, studyNumber)),
  };
  const patientsStatus = useSelector(state => state.patients.status);

  const [editFeedback, setEditFeedback] = useState('');
  const [key, setKey] = useState('');
  const [isForgetPasswordDisabled, setIsForgetPasswordDisabled] = useState(false);
  const [forgetPasswordTimeout] = useState(10000);
  const [navigate, setNavigate] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);


  useEffect(() => { // equivalent to onComponentDidMount()
    TitleService.setTitle('Edit');
  }, []);

  useEffect(() => {
    if (patientsStatus === 'idle') {
      dispatch(fetchPatients());
    }
  }, [patientsStatus, dispatch]);

  useEffect(() => {
    const editPageEvent = new CustomEvent('editPageEvent', { detail: { currentPatient } });
    document.dispatchEvent(editPageEvent);
  }, [currentPatient]);

  const cancelInput = async () => setNavigate(true);

  // eslint-disable-next-line consistent-return
  const onSubmit = async (event) => {
    event.preventDefault();

    // save changes
    try {
      // update patient data in redux store
      dispatch(upsertPatientByStudyNumber({
        study_number: currentPatient.study_number,
        editedPatient: currentPatient,
      }));

      // post changes to backend
      const body = {
        study_number: currentPatient.study_number,
        name: currentPatient.name,
        surname: currentPatient.surname,
        sex: currentPatient.sex,
        birthday: currentPatient.birthday,
        phone: currentPatient.phone,
        city: currentPatient.city,
        street: currentPatient.street,
        postcode: currentPatient.postcode,
        treatment: currentPatient.treatment,
      };
      const endpoint = process.env.REACT_APP_EDIT_ENDPOINT.replace('%', currentPatient.id);
      await ApiService.postData(endpoint, body);
      setEditFeedback(t('edit.change_success'));

      // navigate to dashboard
      return setNavigate(true);
    } catch (err) {
      setEditFeedback(t('edit.change_failure'));
    }
  };

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  // eslint-disable-next-line no-unused-vars
  const onActivatePatient = async () => {
    currentPatient.is_active = currentPatient.is_active === 1 ? 0 : 1;
    // if patient is set to inactive render dashboard and deactivate patient in the backend
    if (currentPatient.is_active === 0) {
      const activeEndpoint = process.env.REACT_APP_EDIT_ACTIVE_ENDPOINT.replace('%', currentPatient.id);
      const resp = await ApiService.postData(activeEndpoint, { status: currentPatient.is_active });
      if (resp.status === 200) {
        setNavigate(true);
        dispatch(deactivatePatient({ study_number: currentPatient.study_number }));
      }
    }
  };

  const onPasswordGenerate = async () => {
    try {
      const endpoint = process.env.REACT_APP_GET_FORGET_KEY_ENDPOINT.replace('%', currentPatient.id);
      const response = await ApiService.getData(endpoint);
      const responseKey = response.data.key;
      setKey(`${t('edit.unlocking_key')} ${responseKey}`);
      setIsForgetPasswordDisabled(true);
      setTimeout(() => setIsForgetPasswordDisabled(false), forgetPasswordTimeout);
    } catch (err) {
      setKey(err.message);
    }
  };

  if (navigate) return <Navigate to="/"/>;

  const currentMessage = editFeedback || t('edit.confirm');
  const cancelMessage = editFeedback || t('edit.cancel');
  const modalMessage1 = editFeedback || t('edit.deactivate_message_1');
  const modalMessage2 = editFeedback || t('edit.deactivate_message_2');
  const modalMessage3 = editFeedback || t('edit.deactivate_message_3');
  const activeMessage = currentPatient.is_active ? t('edit.deactivate_patient') : t('edit.activate_patient');
  const keyMessage = key || t('edit.new_unlocking_key');
  const surnameName = `${currentPatient.surname}, ${currentPatient.name}`;
  const nameSurname = `${currentPatient.surname}, ${currentPatient.name}`;

  let formReturn = <span/>;
  if (currentPatient) {
    formReturn = (
      <PatientInformationForm
        editFeedback={editFeedback}
        setEditFeedback={setEditFeedback}
        currentPatient={currentPatient}
        onSubmit={onSubmit}
        submitLabel={currentMessage}
        onCancel={cancelInput}
        cancelLabel={cancelMessage}
        editMode
        availableTreatments={useAvailableTreatments()}
      />);
  }

  const renderEditPage = () => (
    <div className="editBeginning">
      <div className="password-reset overlay">
        <label className="studyNumber" data-testid={e2eTestIds.editPatient.patientId}>{studyNumber}</label>
        <button
          className="buttonTransparent passwordResetButton"
          disabled={isForgetPasswordDisabled}
          onClick={onPasswordGenerate}
        >{keyMessage}
        </button>
        <button className="deactivateButton" onClick={openModal}>{activeMessage}</button>
      </div>
      {formReturn}
      <div className="deactivateContainer overlay">
        <Modal className="border-radius-2" show={modalIsOpen} centered>
          <Modal.Header className="modalTextSize text-left">
            <Modal.Title id="contained-modal-title-vcenter">
              <FontAwesomeIcon
                className="font"
                icon={faTriangleExclamation}
                size="sm"
              /> {surnameName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalMessage1}
            {nameSurname}
            {modalMessage2}
            <br/>
            <br/>
            {modalMessage3}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="buttonSave"
              type="submit"
              onClick={onActivatePatient}
            >{activeMessage}
            </button>
            <button className="buttonModalCancel" onClick={closeModal}>{cancelMessage}</button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>);

  // for some reason there is an empty object instead of
  // undefined when no data was found. Feel free to improve this
  // https://stackoverflow.com/a/679937
  const isCurrentPatient = !(Object.keys(currentPatient).length === 0 &&
    currentPatient.constructor === Object);
  // Check if the requested patient exists. If not, show an error message
  if (!isCurrentPatient) {
    return (<Error code={404} hint={`${t('edit.patient_not_found_error')} ${studyNumber}`}/>);
  }
  const isCurrentPatientActive = currentPatient.is_active !== 0;
  if (!isCurrentPatientActive) {
    return (<Error code={410} hint={`${t('edit.patient_deactivated_error', { patId: studyNumber })}`}/>);
  }
  return renderEditPage();
}

export default withTranslation('common')(EditComponent);

EditComponent.propTypes = {
  t: PropTypes.func.isRequired,
};
