import { withTranslation } from 'react-i18next';
import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ListGroup } from 'react-bootstrap';
import { selectPatientById, setPatientCommentsChecked } from '../../redux/patientsSlice';
import TitleService from '../../services/title/titleService';
import helpers from '../../helpers/staticHelperMethods';
import patientCommentsCheckedService from '../../services/patientCommentsCheckedService/patientCommentsCheckedService';
import './PatientCommentOverview.scss';


const PatientCommentOverview = (props) => {
  const { t } = props;
  const { patientId: patientIdString } = useParams();

  const patientCardClassName = (messageTs, lastCheckedTs) => {
    const baseClassName = 'card col-12';
    if (new Date(messageTs).getTime() > new Date(lastCheckedTs).getTime()) {
      return `${baseClassName} comment-card-orange-border-left`;
    }
    return `${baseClassName} comment-card-gray-border-left`;
  };

  const patientId = parseInt(patientIdString, 10);
  const currentPatient = {
    ...useSelector((state) => selectPatientById(state, patientId)),
  };

  useEffect(() => {
    if (currentPatient) {
      // change title
      // Add translations to distingush between Patient Details / Overview / Questionnare in Title
      TitleService.setTitle(`${currentPatient.study_number} - ${t('patientCommentOverview.overview')}`);
      window.scrollTo(0, 0);
    }
    // trigger event for header generation
    const patientDetailPageEvent = new CustomEvent('patientDetailPageEvent', { detail: currentPatient });
    document.dispatchEvent(patientDetailPageEvent);

    if (!currentPatient.isCommentsChecked) {
      patientCommentsCheckedService.setPatientCommentsChecked(currentPatient.id);
    }
  }, [currentPatient]);

  const dispatch = useDispatch();
  const onBackButtonPressed = useCallback(() => {
    dispatch(setPatientCommentsChecked({ study_number: currentPatient.study_number }));
    window.removeEventListener('popstate', onBackButtonPressed);
  }, [currentPatient.study_number]);

  useEffect(() => {
    window.addEventListener('popstate', onBackButtonPressed);
  }, []);

  return (
    <div className="page-container">
      <div className="card col-12 corner-radius">
        <div className="card-body title-container">
          <div className="title">
            {t('patientCommentOverview.sendComment')}
          </div>
        </div>
      </div>
      {
        currentPatient.patientComments.length > 0 ?
          <div className="list-container">
            <ListGroup>
              {
                currentPatient.patientComments.map(patientCommentDto => {
                  const { value, timestamp } = patientCommentDto;
                  const formattedDate = helpers.getDateString(new Date(timestamp), t, true, true);
                  const sendDate = `${t('patientCommentOverview.sendDate')}: ${formattedDate}`;
                  const newMessageClassName = patientCardClassName(
                    timestamp, currentPatient.commentsCheckedDate,
                  );
                  return (
                    <ListGroup.Item
                      key={timestamp}
                      className={newMessageClassName}
                    >
                      <div>
                        <p>{sendDate}</p>
                        <p>{value}</p>
                      </div>
                    </ListGroup.Item>
                  );
                })
              }
            </ListGroup>
          </div> :
          <p className="no-comments-text">{t('patientCommentOverview.noCommentsText')}</p>
      }
    </div>
  );
};

PatientCommentOverview.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(PatientCommentOverview);
