
export default {
  faultyUrlOrEndpoint: 'Please pass parameters url and endpoint',
  noPatientsResponseErr: 'Could not load patients data',
  faultyResponseParameter: 'Missing response parameters',
  noIdErr: 'Please pass an id to fulfill the method requirements',
  noDateErr: 'Please pass an date to fulfill the method requirements',
  noNewDataResponseErr: 'Could check if there are new data entries',
  noPatientDetailsResponseErr: 'Could not load data of patient',
  invalidPatientDetailsResponseErr: 'Invalid response for patient details',
  propertyIsNotAnArrayErr: 'Properties parameter must be an array',
  noValueLimitsResponseErr: 'Could not load value limits data',
  incompatibleValueLimitsErr: 'Value Limits are not compatible with this chart. Min and max values must exist',
  multiLineWithoutUnitsErr: 'A multiline chart must contain a units property to define each line',
  missingDataErr: 'No value limits or chart data passed',
  noValuesErr: 'No vitals and/or symptoms found',
  faultyParameterErr: 'Invalid method parameters',
  noChartDataResponseErr: 'Could not load chart data',
  invalidChartDataResponseErr: 'Could not load data of chart',
  invalidTimeSpanParamErr: 'Please pass a valid time span parameter',
  missingParamErr: 'The Response is missing some parameters',
  networkErr: 'No valid response has been received',
  wrongCredentials: 'Username or password is incorrect',
  serverErr: 'server error. Please consult the development team',
};
