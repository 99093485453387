import PropTypes from 'prop-types';
import SymptomShape from './symptom';

const symptomChartShape = PropTypes.shape({
  chart: PropTypes.func.isRequired,
  // data: PropTypes.array,
  data: PropTypes.arrayOf(SymptomShape).isRequired,
  name: PropTypes.string.isRequired,
});

export default symptomChartShape;
