import PropTypes from 'prop-types';
import ValueLimitDefinition from './valueLimitDefinition';
import ValueLimitDefinitionBloodPressure from './valueLimitDefinitionBloodPressure';

const valueLimitsShape = PropTypes.shape({
  TEMPERATURE: ValueLimitDefinition.isRequired,
  WEIGHT: ValueLimitDefinition.isRequired,
  BLOOD_PRESSURE: ValueLimitDefinitionBloodPressure.isRequired,
  DIARRHOEA: ValueLimitDefinition.isRequired,
  PAIN: ValueLimitDefinition.isRequired,
  SIGNS_BLEEDING: ValueLimitDefinition.isRequired,
  SKIN_RASH: ValueLimitDefinition.isRequired,
  PROBLEMS_SWALLOWING: ValueLimitDefinition.isRequired,
  SORE_MOUTH: ValueLimitDefinition.isRequired,
  NAUSEA: ValueLimitDefinition.isRequired,
  VOMITING: ValueLimitDefinition.isRequired,
  SHORT_BREATH: ValueLimitDefinition.isRequired,
  BURNING_URINATION: ValueLimitDefinition.isRequired,
  FATIGUE: ValueLimitDefinition.isRequired,
  COUGH: ValueLimitDefinition.isRequired,
  DECREASED_APPETITE: ValueLimitDefinition.isRequired,
});

export default valueLimitsShape;
