import React from 'react';
import PropTypes from 'prop-types';
import EvaluationCheckboxes from '../evaluation-checkboxes/EvaluationCheckboxes';

const OrientationEvaluationRow = (props) => (
  <tr>
    <td>{props.question}</td>
    <td>{props.answerPatient}</td>
    <td>{props.answerExpected}</td>
    <td>
      <EvaluationCheckboxes
        groupName={`orientation${props.index}`}
        accepted={props.accepted}
        setAccepted={props.setAccepted}
      />
    </td>
  </tr>
);

export default OrientationEvaluationRow;

OrientationEvaluationRow.propTypes = {
  question: PropTypes.string.isRequired,
  answerPatient: PropTypes.string.isRequired,
  answerExpected: PropTypes.string.isRequired,
  accepted: PropTypes.bool.isRequired,
  setAccepted: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};
