import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './QuestionnaireHeader.scoped.scss';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import DayCalculator from '../../common/DayCalculator';
import HeaderContainer from '../../wrapper/header/HeaderContainer';
import helpers from '../../../helpers/staticHelperMethods';

function QuestionnaireHeader(props) {
  const {
    t, patientDetailData,
  } = props;

  // for some reason there is an empty object instead of
  // undefined when no data was found. Feel free to improve this
  // https://stackoverflow.com/a/679937
  const isPatientDetailData = !(Object.keys(patientDetailData).length === 0);
  // If there is no patient data, render only the back button
  if (!isPatientDetailData) {
    return <HeaderContainer/>;
  }

  const {
    name, surname, birthday,
    id, transplant_date: transplantDate, sex, phone, mode,
  } = patientDetailData;


  const daysSince = transplantDate ?
    (<DayCalculator
      id={id}
      dayOfTransplantation={transplantDate}
      tooltipFlag={false}
    />) : <div/>;

  const age = helpers.getAge(birthday);

  const surnameName = `${surname || '?'}, ${name || '?'}`;

  const sexIcon = helpers.getSexIcon(sex);
  const homeIcon = helpers.getHomeIcon(mode);

  return (
    <HeaderContainer>
      <span className="patientName">{surnameName}
        <sup>{sexIcon}</sup>
      </span>
      {age ? <span className="patientAge">{age} {t('patientHeader.age')} </span> : null}
      <span className="daySince"> {daysSince} </span>
      <span className="homeIcon">{homeIcon}</span>
      {phone ?
        <span className="phone">
          <FontAwesomeIcon size="1x" icon={faPhone}/> {phone}
        </span>
        : null}
    </HeaderContainer>
  );
}


QuestionnaireHeader.propTypes = {
  t: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  patientDetailData: PropTypes.object.isRequired,
};


export default withTranslation('common')(QuestionnaireHeader);


