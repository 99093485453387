import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Table } from 'react-bootstrap';
import OrientationEvaluationRow from './OrientationEvaluationRow';

const OrientationEvaluation = (props) => {
  const { t } = props;

  return (
    <div className="card col-12 corner-radius">
      <div className="card-body">
        <p>{t('questionnaire.orientation.title')}</p>
        <Table striped hover>
          <thead>
            <tr>
              <th>{t('questionnaire.orientation.tableHeaders.question')}</th>
              <th>
                {t('questionnaire.orientation.tableHeaders.answerPatient')}
              </th>
              <th>
                {t('questionnaire.orientation.tableHeaders.answerExpected')}
              </th>
              <th>{t('questionnaire.orientation.tableHeaders.evaluation')}</th>
            </tr>
          </thead>
          <tbody>
            <OrientationEvaluationRow
              question={t('questionnaire.orientation.questions.year')}
              answerPatient={props.data.year.actual ?? t('questionnaire.wasSkippedPlaceholder')}
              answerExpected={props.data.year.expected}
              accepted={props.data.year.accepted}
              setAccepted={(value) =>
                props.setOverwrite({ year: value })
              }
              index={1}
            />
            <OrientationEvaluationRow
              question={t('questionnaire.orientation.questions.month')}
              answerPatient={props.data.month.actual ?? t('questionnaire.wasSkippedPlaceholder')}
              answerExpected={new Date(2023, props.data.month.expected).toLocaleString(undefined, { month: 'long' })}
              accepted={props.data.month.accepted}
              setAccepted={(value) =>
                props.setOverwrite({ month: value })
              }
              index={2}
            />
            <OrientationEvaluationRow
              question={t('questionnaire.orientation.questions.city')}
              answerPatient={props.data.city.actual ?? t('questionnaire.wasSkippedPlaceholder')}
              answerExpected={props.data.city.expected}
              accepted={props.data.city.accepted}
              setAccepted={(value) =>
                props.setOverwrite({ city: value })
              }
              index={3}
            />
            <OrientationEvaluationRow
              question={t('questionnaire.orientation.questions.hospital')}
              answerPatient={props.data.hospital.actual ?
                t(`questionnaire.orientation.answers.hospital.${props.data.hospital.actual}`)
                : t('questionnaire.wasSkippedPlaceholder')}
              answerExpected={t(`questionnaire.orientation.answers.hospital.${props.data.hospital.expected}`)}
              accepted={props.data.hospital.accepted}
              setAccepted={(value) =>
                props.setOverwrite({ hospital: value })
              }
              index={4}
            />
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default withTranslation('common')(OrientationEvaluation);

OrientationEvaluation.propTypes = {
  t: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  setOverwrite: PropTypes.func.isRequired,
};
