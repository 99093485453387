import React from 'react';
import PropTypes from 'prop-types';
import './SeverityIndicator.scss';
import ReactTooltip from 'react-tooltip';
import { withTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import TrendIndicator from './trend-indicator/TrendIndicator';
import Labels from './labels/Labels';
import Bar from './bar/Bar';
import helpers from '../../../../../../helpers/staticHelperMethods';

/** @classdesc Sums up JSX-Code from Bar, Labels and Trendindicator
 * @see Bar
 * @see Labels
 * @see TrendIndicator
 * @param {number} props.max Maximal Volume of Bar (mostly 10)
 * @param {number} props.min Minimal Volume of Bar (always 0)
 * @param {number} props.currentValue Value that should be displayed (between min-max)
 * @param {number} props.previousValue Displayed Value right before props.currentValue
 * @param {string} props.barColor Cholor of the bar depending on symptoms severity
 * @returns {function}   JSX code that renders Symptombars with indicator and labels,
 * which will be displayed in the view
 * @class
 * @hideconstructor
 */
const severityIndicator = (props) => {
  const {
    t,
    symptom,
    currentValue,
    previousValue,
    max,
    min,
    isObsolete,
    currentValueTimestamp,
    previousValueTimestamp,
  } = props;

  const previousDateString = `(${helpers.getDateString(new Date(previousValueTimestamp), t)})`;
  const currentDateString = helpers.getDateString(new Date(currentValueTimestamp), t);
  const previousValueString = `(${previousValue})`;
  const tooltipID = uuidv4();
  const severityIndicatorClass = previousValue === 0 && currentValue === 0 ? 'SeverityIndicator symptoms-card-opacity' : 'SeverityIndicator';

  const toolTip = (previousValue !== 0 || currentValue !== 0 ?
    <ReactTooltip id={tooltipID} place="right" effect="solid" multiline backgroundColor="#545454">
      <center>
        <h6><b>{currentDateString} </b> {previousDateString}<br/></h6>
        <h6><b>{currentValue} </b> {previousValueString}<br/>
          {symptom}
        </h6>
      </center>
    </ReactTooltip> : <span/>);

  return (
    <div
      className={severityIndicatorClass}
      data-tip=""
      data-for={tooltipID}
    >
      <Bar
        min={min}
        max={max}
        currentValue={currentValue}
      />
      <Labels
        currentValue={currentValue}
        isBinary={max === 1}
        data-testid={props['data-testid']}
      />
      <TrendIndicator
        isObsolete={isObsolete}
        currentValue={currentValue}
        previousValue={previousValue}
        min={min}
        max={max}
      />
      { toolTip }
    </div>
  );
};

export default withTranslation('common')(severityIndicator);

severityIndicator.propTypes = {
  isObsolete: PropTypes.bool.isRequired,
  symptom: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  barColor: PropTypes.string.isRequired,
  currentValue: PropTypes.number.isRequired,
  previousValue: PropTypes.number.isRequired,
  currentValueTimestamp: PropTypes.number.isRequired,
  previousValueTimestamp: PropTypes.number.isRequired,
  'data-testid': PropTypes.string,
};

severityIndicator.defaultProps = {
  'data-testid': undefined,
};
