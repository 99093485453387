import { FormCheck } from 'react-bootstrap';
import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const EvaluationCheckboxes = (props) => {
  const { t, groupName } = props;

  return (
    <>
      <FormCheck
        name={groupName}
        inline
        label={t('questionnaire.accepted')}
        type="radio"
        defaultChecked={props.accepted}
        onClick={() => props.setAccepted(true)}
      />
      <FormCheck
        name={groupName}
        inline
        label={t('questionnaire.denied')}
        type="radio"
        checked={props.accepted === false}
        onChange={() => props.setAccepted(false)}
      />
      {props.accepted === null && (
        <FontAwesomeIcon
          icon={faCircleExclamation}
          className="attention-needed"
          size="2x"
        />
      )}
    </>
  );
};

export default withTranslation('common')(EvaluationCheckboxes);

EvaluationCheckboxes.propTypes = {
  t: PropTypes.func.isRequired,
  groupName: PropTypes.string.isRequired,
  accepted: PropTypes.bool,
  setAccepted: PropTypes.func.isRequired,
};

EvaluationCheckboxes.defaultProps = {
  accepted: null,
};
