const e2eTestIds = {
  header: {
    user: 'care-header-user',
  },
  subHeader: {
    dashboard: {
      inviteLink: 'care-subheader-dashboard-invite-link',
    },
  },
  login: {
    username: 'care-login-username',
    password: 'care-login-password',
    submit: 'care-login-submit',
  },
  dashboard: {
    patientCard: {
      container: (patientSurname, patientFirstname) => `care-dashboard-patient-card-container-${patientSurname}-${patientFirstname}`,
      patientName: 'care-dashboard-patient-card-patient-name',
      edit: 'care-dashboard-patient-card-patient-edit',
      temperature: 'care-dashboard-patient-card-temperature',
      weight: 'care-dashboard-patient-card-weight',
      bloodPressure: 'care-dashboard-patient-card-blood-pressure',
      pulse: 'care-dashboard-patient-card-pulse',
      wellBeing: 'care-dashboard-patient-card-well-being',
      symptom: (symptom) => `care-dashboard-patient-card-symptom-${symptom}`,
    },
  },
  patientForm: {
    patientId: 'care-patient-form-patient-id',
    treatment: 'care-patient-form-treatment',
    firstname: 'care-patient-form-firstname',
    surname: 'care-patient-form-surname',
    birthday: 'care-patient-form-birthday',
    sex: 'care-patient-form-sex',
    phone: 'care-patient-form-phone',
    street: 'care-patient-form-street',
    postcode: 'care-patient-form-post-code',
    city: 'care-patient-form-city',
    submit: 'care-patient-form-submit',
  },
  registration: {
    registrationKey: 'care-registration-key',
  },
  editPatient: {
    patientId: 'care-edit-patient-id',
  },
  components: {
    medicationAdherence: {
      immuneSuppressive: 'care-components-medication-adherence-immune-suppressive',
      otherMedication: 'care-components-medication-adherence-other-medication',
    },
  },
};

export default e2eTestIds;
